import commonFun from "./commonFun";
import md5 from "md5";

function getHash(params) {
    if (typeof params == "string") {
        return md5(params);
    } else if (typeof params == "object") {
        let arr = [];
        for (let i in params) {
            if(i!=='api'){
                arr.push((i + "_" + params[i]));
            }
        }
        // console.log('getHash:'+arr.join("_"));
        //return md5(arr.join("_"));
        return arr.join("_");
    }
}

/**
 * 对接口进行签名
 * @param {object} params
 * @returns {object}
 */
function getSign(params){
    params['session'] = commonFun.createCUUID();
    params['ptoken'] = commonFun.getGlobalData('ptoken');
    params['timestamp'] = (new Date()).getTime();
    params['sign'] = commonFun.getSign(params);
    return params;
}

/**
 * 获取头部导航配置
 */
function getMainNav(){
    let api = commonFun.formatUrl("/common/main_nav");
    commonFun.requestGet(api,{},function (result) {
        if(result.code===0){
            commonFun.setGlobalData('mainNavConfig', result);//表单提交时必
        }
    });
}
/**
 * 获取club首页广告
 * @param {object} params 参数{position,type}
 * @param {function} feedback
 */
function getClubHomeAdverts(feedback=function () {}){
    let api = commonFun.formatUrl("/club/advert/get_home_advert");
    let key = 'en_club_home_adverts';

    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }

    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    commonFun.requestGet(api,{},function (result) {
        if(result.code===0 && !commonFun.isNull(result['data'])){
            commonFun.setGlobalData(key,result['data']);
            feedback(result['data']);
        }
    });
}

/**
 * 获取club最新一个广告
 * @param {object} params 参数{position,type}
 * @param {function} feedback
 */
function getClubLastAdvert(params={position:'homepage',type:'text'},feedback=function () {}){
    let api = commonFun.formatUrl("/club/advert/get_last_advert");
    let tmp = params;
    tmp['api'] = api;
    let key = 'en_club_last_advert_'+getHash(tmp);
    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    params = getSign(params);

    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    commonFun.requestPost(api,params,function (result) {
        if(result.code===0 && !commonFun.isNull(result['data'])){
            commonFun.setGlobalData(key,result['data']);
            feedback(result['data']);
        }
    });
}

/**
 * 获取招募项目(events)
 * @param {object} params 参数{page,limit}
 * @param {function} feedback
 */
function getEvents(params={page:1,limit:3},feedback=function () {}){
    let api = commonFun.formatUrl("/club/project/getAllList/event");
    let tmp = params;
    tmp['api'] = api;
    let key = 'events_'+getHash(tmp);
    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    params = getSign(params);
    commonFun.requestPost(api,params,function (result) {
        if(result.code===0 && !commonFun.isNull(result['data'])){
            commonFun.setGlobalData(key,result['data']);
            feedback(result['data']);
        }
    });
}


/**
 * 获取活动项目(club project)
 * @param {object} params 参数{page,limit}
 * @param {function} feedback
 */
function getProjects(params={page:1,limit:4},feedback=function () {}){
    let api = commonFun.formatUrl("/club/project/getAllList/club");
    let tmp = params;
    tmp['api'] = api;
    let key = 'projects_'+getHash(tmp);
    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    params = getSign(params);
    commonFun.requestPost(api,params,function (result) {
        if(result.code===0 && !commonFun.isNull(result['data'])){
            commonFun.setGlobalData(key,result['data']);
            feedback(result['data']);
        }
    });
}

/**
 * 获取招募项目详情(events)
 * @param {string} id 项目id
 * @param {function} feedback
 */
function getEventsDetail(id,feedback=function () {}){
    if(commonFun.isNull(id)){
        return;
    }
    let api = commonFun.formatUrl("/club/project/detail/v2/"+id);
    let key = 'events_detail_'+id;
    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }

    commonFun.requestGet(api,{},function (result) {
        if(result.code===0){
            commonFun.setGlobalData(key,result);
            feedback(result);
        }
    });
}

/**
 * 获取项目回片(Featured Works)
 * @param {object} params 参数{page,limit}
 * @param {function} feedback
 */
function getFeatures(params={page:1,limit:8},feedback=function () {}){
    let api = commonFun.formatUrl("/club/blog/getlist");
    let tmp = params;
    tmp['api'] = api;
    let key = 'features_' + getHash(tmp);
    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    params = getSign(params);
    commonFun.requestPost(api,params,function (result) {
        if(result.code===0 && !commonFun.isNull(result['data'])){
            commonFun.setGlobalData(key,result['data']);
            feedback(result['data']);
        }
    });
}

/**
 * 获取项目回片分类归总(Featured Works)
 * @param {object} params 参数{page,limit}
 * @param {function} feedback
 */
function getFeaturesCategory(params={page:1,limit:20},feedback=function () {}){
    let api = commonFun.formatUrl("/club/blog/category");
    let tmp = params;
    tmp['api'] = api;
    let key = 'features_category_' + getHash(tmp);
    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    params = getSign(params);
    commonFun.requestPost(api,params,function (result) {
        if(result.code===0 && !commonFun.isNull(result['data'])){
            commonFun.setGlobalData(key,result['data']);
            feedback(result['data']);
        }
    });
}

/**
 * 获取项目回片的广告轮播(Featured Works advert)
 * @param {object} params 参数{page,limit}
 * @param {function} feedback
 */
function getFeaturesAdverts(params={page:1,limit:5},feedback=function () {}){
    let api = commonFun.formatUrl("/club/advert/getAllList");
    let tmp = params;
    tmp['api'] = api;
    let key = 'features_ad_' + getHash(tmp);
    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    params = getSign(params);
    commonFun.requestPost(api,params,function (result) {
        if(result.code===0 && !commonFun.isNull(result['data'])){
            commonFun.setGlobalData(key,result['data']);
            feedback(result['data']);
        }
    });
}

/**
 * 获取创作者列表(Meet the Creators) 多用户多视频
 * @param {object} params 参数{page,limit}
 * @param {function} feedback
 */
function getCreatorsBlogs(params={page:1,limit:20},feedback=function () {}){
    let api = commonFun.formatUrl("/club/creators/blogs");
    let tmp = params;
    tmp['api'] = api;
    let key = 'creators_blogs_list_' + getHash(tmp);
    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    params = getSign(params);
    commonFun.requestPost(api,params,function (result) {
        if(result.code===0 && !commonFun.isNull(result['data'])){
            commonFun.setGlobalData(key,result['data']);
            feedback(result['data']);
        }
    });
}

/**
 * 获取创作者信息及作品列表(Creator Works) 单用户多视频
 * @param {object} params 参数{creator_id:1,page:1,limit:20}
 * @param {function} feedback
 */
function getCreatorBlogsList(params={creator_id:0,page:1,limit:20},feedback=function () {}){
    let api = commonFun.formatUrl("/club/creators/blogs/list");
    let tmp = params;
    tmp['api'] = api;
    let key = 'creator_blogs_list_' + getHash(tmp);
    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    params = getSign(params);
    commonFun.requestPost(api,params,function (result) {
        if(result.code===0 && !commonFun.isNull(result['data'])){
            commonFun.setGlobalData(key,result['data']);
            feedback(result['data']);
        }
    });
}

/**
 * 招募表单申请提交
 * @param params
 * @param feedback
 */
function submitApplyForm(params={},feedback=function () {}) {
    let api = commonFun.formatUrl("/club/project/apply");
    params = getSign(params);
    commonFun.requestPost(api,params,function (result) {
        feedback(result);
    });
}

/**
 * 获取club的隐私政策
 */
function getClubPolicy(feedback=function () {}){
    let api = commonFun.formatUrl("/club/rule/policy/data");
    let key = 'en_club_rule_policy';

    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }

    if(!commonFun.isNull(commonFun.getGlobalData(key))){
        feedback(commonFun.getGlobalData(key));
        return true;
    }
    commonFun.requestGet(api,{},function (result) {
        if(result.code===0 && !commonFun.isNull(result)){
            commonFun.setGlobalData(key,result);
            feedback(result);
        }
    });
}


/**
 * 页面访问信息
 */
function viewerTracking (setting) {
    let domain = document.domain;
    if(domain!=="explore.zhiyun-tech.com"){
        return false;
    }
    let ua = navigator.userAgent.toLowerCase();//浏览器
    let height = window.screen.height;//高
    let width = window.screen.width;//宽
    if(commonFun.isNull(setting.type) && commonFun.isNull(setting.page)){
        return false;
    }
    let referrer = document.referrer;
    let local_lang = (navigator.browserLanguage ? navigator.browserLanguage : navigator.language).toLowerCase();
    if(commonFun.isNull(setting.type)){
        setting.type = document.querySelector('meta[name="type"]')?.getAttribute("content");
    }
    if(commonFun.isNull(setting.page)){
        setting.page = document.querySelector('meta[name="page"]')?.getAttribute("content");
    }
    let data = {
        'uuid': commonFun.createCUUID(),'type':setting.type,'page':setting.page, 'local_lang': local_lang,'language': commonFun.getLanguage(),
        'url': window.location.href,'referrer': referrer,'referrer_domain':commonFun.getDomain(referrer),
        'browser': commonFun.getBrowser(),'ua':ua, 'screen': width + '*' + height,'os':commonFun.getPlatform(),
        'platform': commonFun.getQueryVariable('platform'),'tag': commonFun.getQueryVariable('tag'),
        'action': setting.action
    };
    let api = commonFun.getGlobalData('analysisApi') + '/explore';
    commonFun.requestPost(api,data)
}

/**
 * 点击信息信息
 */
function clickTracking () {
    // console.log("clickTracking")
    //影响整体暂停使用
    // if(document.domain!=="explore.zhiyun-tech.com" && document.domain!=="explore.zhiyun-tech.com"){
    //     return false;
    // }
    setTimeout(function () {
        let dom = document.getElementsByTagName("a");
        let canClick = true;
        for (let i=0;i<dom.length;i++){
            if(commonFun.isNull(dom[i].href) || dom[i].href==='javascript:;'){
                continue;
            }
            dom[i].onmousedown = function (e){
                e.stopPropagation();//阻止事件冒泡
                if(canClick){
                    canClick=false;
                    commonFun.requestPost(commonFun.getGlobalData('analysisApi') + '/browser/explore/click'
                        ,{'uuid': commonFun.createCUUID(),'url': window.location.href,'in_url':document.referrer,'out_url':dom[i].href,'language':commonFun.getLanguage()}
                        ,()=>{canClick=true})
                }
            }

        }
    },500);
}

export default{
    getHash,getMainNav,getClubHomeAdverts,getClubLastAdvert,getEvents,getProjects,getEventsDetail
    ,getFeatures,getFeaturesCategory,getFeaturesAdverts,getCreatorsBlogs,getCreatorBlogsList,submitApplyForm,getClubPolicy
    ,viewerTracking,clickTracking
}