import React, {Component} from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonFun from "../../../functions/commonFun";
import apiService from "../../../functions/apiService";
import styles from '../../css/creatorsClub/PageIndex.module.css'
import {Link} from "react-router-dom";
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Mousewheel, Keyboard,Autoplay } from "swiper";


gsap.registerPlugin(ScrollToPlugin);

/**
 * Creators Club 首页
 */
export default class PageIndex extends Component {
    httpRequest=false;//是否已经进行HTTP请求，防止重复请求
    canTrackingEnd=false;//能否发送页面结束追踪
    constructor(props) {
        super(props);
        this.state = {
            mainNavConfig:null,
            advertTextData:null,
            advertData:null,
            eventData:null,
            featureData:null,
            player:null,
            curWindowWidth:commonFun.getWidth(),
        }
    }

    componentDidMount() {
        if(!this.httpRequest){
            this.httpRequest = true;

            if(commonFun.isNull(commonFun.getGlobalData('ptoken'))){//无法获取ptoken
                let timerCount = 0;
                let pTimer = setInterval( ()=> {
                    timerCount++;
                    if(timerCount>10){//防止死循环
                        clearInterval(pTimer)
                    }
                    if(!commonFun.isNull(commonFun.getGlobalData('ptoken'))){
                        clearInterval(pTimer)
                        apiService.getFeaturesAdverts({page:1,limit:5,position:'homepage',type:'image'},this.getAdvertData.bind(this));
                        apiService.getEvents({limit:3},this.getEventData.bind(this));
                        apiService.getFeatures({limit:6},this.getFeatureData.bind(this));
                    }
                },1000)
            }else{
                apiService.getFeaturesAdverts({page:1,limit:5,position:'homepage',type:'image'},this.getAdvertData.bind(this));
                apiService.getEvents({limit:3},this.getEventData.bind(this));
                apiService.getFeatures({limit:6},this.getFeatureData.bind(this));
            }


        }
        let timer = setInterval(()=>{//更新storage数据，不可删除
            if(!commonFun.isNull(commonFun.getGlobalData('mainNavConfig'))){
                this.setState({mainNavConfig:commonFun.getGlobalData('mainNavConfig')});
                clearInterval(timer);
            }
        },300);
        window.addEventListener('resize', this.handleResize.bind(this)); //监听窗口大小改变

        commonFun.setGlobalData('MainNavSelected',-1);

        setTimeout(()=>{//追踪代码
            if(!this.canTrackingEnd){
                apiService.viewerTracking({page:'index',type:'club',action:'start'});
                this.canTrackingEnd = true;
                apiService.clickTracking();//A标签点击追踪
            }

        },commonFun.validSeconds);

        commonFun.setSeoInfos("PageIndex","club","Explore Club Creators","","");

        // setTimeout(()=>{
        //     console.log("开始调整.........")
        //     // console.log(document.querySelector("."+styles.bannerVideo).offsetHeight)
        //     // let doms = document.querySelectorAll("."+styles.featuredBoxImg+">a>img");
        //     let doms = document.getElementsByTagName("img");
        //     for (const key in doms) {
        //         // console.log("当前图片宽度："+doms[key].offsetWidth,"当前图片高度："+doms[key].offsetHeight)
        //         console.log("原始当前图片宽度："+(doms[key].naturalWidth),"原始当前图片高度："+doms[key].naturalHeight)
        //         let s= (doms[key].offsetWidth/doms[key].naturalWidth);
        //         if(s>1){
        //             s = (doms[key].naturalWidth/doms[key].offsetWidth);
        //         }
        //         console.log("缩放比例="+s)
        //         doms[key].style.width = doms[key].offsetWidth + "px";
        //         doms[key].style.height = (s * doms[key].naturalHeight) + "px";
        //     }
        //     // console.log(doms)
        //     console.log("结束调整.........")
        // },2000);
    }

    componentWillUnmount() {
        if(this.canTrackingEnd){
            apiService.viewerTracking({page:'index',type:'club',action:'end'});
        }
        window.removeEventListener('resize',this.handleResize.bind(this));
        this.canTrackingEnd=false;
    }

    /**
     * 更新容器大小
     */
    handleResize(){
        this.setState({curWindowWidth:commonFun.getWidth()})
    }

    /**
     * 获取adverts广告轮播数据
     * @param result
     */
    getAdvertData(result){
        if(!commonFun.isNull(result)){
            if(!commonFun.isNull(result)){
                this.setState({advertData:result});
            }
        }
    }

    /**
     * 获取广告数据
     * @param result
     */
    getClubLastAdvert(result){
        if(!commonFun.isNull(result)){
            this.setState({advertTextData:result})
        }
    }

    /**
     * 获取Event数据
     * @param result
     */
    getEventData(result){
        if(!commonFun.isNull(result)){
            this.setState({eventData:result})
        }
    }

    /**
     * 获取Event数据
     * @param result
     */
    getFeatureData(result){
        if(!commonFun.isNull(result)){
            this.setState({featureData:result})
        }
    }

    leanMoreClick(){
        gsap.to(window, {duration: 1, scrollTo:`#${styles.heroContainer}`});//回到0高度
    }


    semiWheel() {
        return <div className={styles.wheelBannerContainer}>
            <div className={styles.leftArrow}></div>
            <div className={styles.rightArrow}></div>
            <Swiper
                cssMode={true}
                loop={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard,Autoplay]}
                className="mySwiper"
                pagination={{
                    el: `.${styles.indicatorContainer}`,
                    bulletClass:`${styles.bulletClass}`,
                    bulletActiveClass:`${styles.bulletActiveClass}`,
                    type: 'bullets',
                }}
                navigation={this.state.curWindowWidth<768 ? false : {
                    nextEl: `.${styles.rightArrow}`,
                    prevEl: `.${styles.leftArrow}`,
                }}
                autoplay={{
                    autoplay:true,
                    pauseOnMouseEnter:true,
                    disableOnInteraction:false
                }}
            >
                {
                    this.state.advertData.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className={styles.bannerImgBackground} style={{backgroundImage: `url(${item['src']})`}}>
                                    <div></div>
                                    <a href={item['href']}> </a>
                                    <div></div>
                                </div>
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
            <div className={styles.indicatorContainer}></div>
        </div>;
    }


    render() {
        return <div className={styles.pageContainer}>
                {/*banner ad 小广告*/}
                {
                    // commonFun.isNull(this.state.mainNavConfig) || commonFun.isNull(this.state.mainNavConfig['adInfo']) || commonFun.isNull(this.state.mainNavConfig['adInfo']['data']) || commonFun.isNull(this.state.mainNavConfig['adInfo']['data']['content'])
                    //     ? null
                    //     : <a className={styles.adBannerContainer} href={this.state.mainNavConfig['adInfo']['data']['href']}>
                    //     <div className={styles.adBanner}>{this.state.mainNavConfig['adInfo']['data']['content']}</div>
                    //     <div className={styles.arrow}>
                    //         <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //             <path fillRule="evenodd" clipRule="evenodd" d="M10.1438 4.05444C10.7866 5.11305 11.9418 6.48415 13.9876 7.62688L15.5507 8.49992L13.9876 9.37296C11.9418 10.5157 10.7866 11.8868 10.1438 12.9454C9.82085 13.4772 9.62504 13.9341 9.51185 14.2485C9.45529 14.4057 9.41951 14.5268 9.39891 14.6034C9.38861 14.6416 9.38213 14.6686 9.37874 14.6834L9.37591 14.6961L9.37608 14.6953L9.37653 14.693L9.37684 14.6914L9.37702 14.6905C9.37712 14.69 9.37721 14.6895 8.39535 14.4999C7.41349 14.3103 7.41359 14.3098 7.4137 14.3093L7.41392 14.3081L7.41443 14.3055L7.41565 14.2994L7.41897 14.2833C7.4216 14.2709 7.42505 14.2551 7.42943 14.236C7.43819 14.1978 7.45066 14.1466 7.46764 14.0835C7.50159 13.9574 7.55362 13.7835 7.63006 13.5712C7.78287 13.1466 8.03389 12.5667 8.43424 11.9074C8.88488 11.1652 9.52203 10.3271 10.4139 9.49992H1V7.49992H10.4139C9.52203 6.67274 8.88488 5.83464 8.43424 5.09246C8.03389 4.43312 7.78287 3.85321 7.63006 3.42869C7.55362 3.21634 7.50159 3.04243 7.46764 2.91631C7.45066 2.85323 7.43819 2.80203 7.42943 2.76386C7.42505 2.74478 7.4216 2.72895 7.41897 2.71651L7.41565 2.70042L7.41443 2.69431L7.41392 2.69175L7.4137 2.69059C7.41359 2.69005 7.41349 2.68952 8.39535 2.49992C9.37721 2.31033 9.37712 2.30982 9.37702 2.30933L9.37684 2.30842L9.37653 2.30682L9.37608 2.30456C9.37593 2.30383 9.37586 2.30347 9.37586 2.30347L9.37591 2.30373L9.37874 2.31646C9.38213 2.33121 9.38861 2.35824 9.39891 2.3965C9.41951 2.47304 9.45529 2.59417 9.51185 2.7513C9.62504 3.06575 9.82085 3.5226 10.1438 4.05444Z" fill="white"/>
                    //         </svg>
                    //     </div>
                    // </a>
                }


            {commonFun.isNull(this.state.advertData) ? null : this.semiWheel()}


            {/*Featured Works*/}
            {
                commonFun.isNull(this.state.featureData)
                    ? null
                    : <div className={styles.featuredContainer}>
                        <div className={styles.featuredBigTitleContainer}>
                            <div className={styles.featuredBigTitle}>Featured Works</div>
                            <Link to="/club/features" className={styles.featuredBigMore}>View More <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.1438 3.55444C10.7866 4.61305 11.9418 5.98415 13.9876 7.12688L15.5507 7.99992L13.9876 8.87296C11.9418 10.0157 10.7866 11.3868 10.1438 12.4454C9.82085 12.9772 9.62504 13.4341 9.51185 13.7485C9.45529 13.9057 9.41951 14.0268 9.39891 14.1034C9.38861 14.1416 9.38213 14.1686 9.37874 14.1834L9.37591 14.1961L9.37608 14.1953L9.37653 14.193L9.37684 14.1914L9.37702 14.1905C9.37712 14.19 9.37721 14.1895 8.39535 13.9999C7.41349 13.8103 7.41359 13.8098 7.4137 13.8093L7.41392 13.8081L7.41443 13.8055L7.41565 13.7994L7.41897 13.7833C7.4216 13.7709 7.42505 13.7551 7.42943 13.736C7.43819 13.6978 7.45066 13.6466 7.46764 13.5835C7.50159 13.4574 7.55362 13.2835 7.63006 13.0712C7.78287 12.6466 8.03389 12.0667 8.43424 11.4074C8.88488 10.6652 9.52203 9.82711 10.4139 8.99992H1V6.99992H10.4139C9.52203 6.17274 8.88488 5.33464 8.43424 4.59246C8.03389 3.93312 7.78287 3.35321 7.63006 2.92869C7.55362 2.71634 7.50159 2.54243 7.46764 2.41631C7.45066 2.35323 7.43819 2.30203 7.42943 2.26386C7.42505 2.24478 7.4216 2.22895 7.41897 2.21651L7.41565 2.20042L7.41443 2.19431L7.41392 2.19175L7.4137 2.19059C7.41359 2.19005 7.41349 2.18952 8.39535 1.99992C9.37721 1.81033 9.37712 1.80982 9.37702 1.80933L9.37684 1.80842L9.37653 1.80682L9.37608 1.80456C9.37593 1.80383 9.37586 1.80347 9.37586 1.80347L9.37591 1.80373L9.37874 1.81646C9.38213 1.83121 9.38861 1.85824 9.39891 1.8965C9.41951 1.97304 9.45529 2.09417 9.51185 2.2513C9.62504 2.56575 9.82085 3.0226 10.1438 3.55444Z" fill="black"/>
                            </svg></Link>
                        </div>

                        <div className={styles.moreFeatured}>
                            {
                                this.state.featureData.map((item,key)=> {
                                    // return <a key={`feature_${key}`} className={styles.featuredBox}  onClick={()=>{this.featurePlay(key)}}>
                                    return <div key={`feature_${key}`} className={styles.featuredBox}>
                                        <div className={styles.featuredBoxImg}>
                                            <div className={styles.coverShade}></div>
                                            <a href={item['href']} target='_blank' rel='noreferrer'><img src={this.state.curWindowWidth>768 ? item['poster'] : (item['posterm'] || item['poster'])}  alt='' /> </a>
                                            <div className={styles.featuredBoxInfoContainer}>
                                                <Link className={styles.featuredNickRow} to={`/club/creators/blogs/${item['creatorId']}`}>
                                                    <img className={styles.featuredNick} src={item['sourceIcon']}  alt=''/>
                                                    <div className={styles.featuredNickname}>{item['author']}</div>
                                                </Link>
                                                <div className={styles.featuredTitleContainer}>
                                                    <div>
                                                        <div className={styles.featuredProductName}>{item['productName']}</div>
                                                    </div>
                                                    <div className={styles.featuredTitle}>{item['title']}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>;
                                })
                            }

                        </div>


                    </div>
            }

            {/*Events*/}
            {
                commonFun.isNull(this.state.eventData)
                    ? null
                    : <div className={styles.eventContainer}>
                        <div className={styles.eventBigTitleContainer}>
                            <div className={styles.eventBigTitle}>EVENTS</div>
                            <Link to='/club/events'  className={styles.eventBigMore}>View More <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.1438 3.55444C10.7866 4.61305 11.9418 5.98415 13.9876 7.12688L15.5507 7.99992L13.9876 8.87296C11.9418 10.0157 10.7866 11.3868 10.1438 12.4454C9.82085 12.9772 9.62504 13.4341 9.51185 13.7485C9.45529 13.9057 9.41951 14.0268 9.39891 14.1034C9.38861 14.1416 9.38213 14.1686 9.37874 14.1834L9.37591 14.1961L9.37608 14.1953L9.37653 14.193L9.37684 14.1914L9.37702 14.1905C9.37712 14.19 9.37721 14.1895 8.39535 13.9999C7.41349 13.8103 7.41359 13.8098 7.4137 13.8093L7.41392 13.8081L7.41443 13.8055L7.41565 13.7994L7.41897 13.7833C7.4216 13.7709 7.42505 13.7551 7.42943 13.736C7.43819 13.6978 7.45066 13.6466 7.46764 13.5835C7.50159 13.4574 7.55362 13.2835 7.63006 13.0712C7.78287 12.6466 8.03389 12.0667 8.43424 11.4074C8.88488 10.6652 9.52203 9.82711 10.4139 8.99992H1V6.99992H10.4139C9.52203 6.17274 8.88488 5.33464 8.43424 4.59246C8.03389 3.93312 7.78287 3.35321 7.63006 2.92869C7.55362 2.71634 7.50159 2.54243 7.46764 2.41631C7.45066 2.35323 7.43819 2.30203 7.42943 2.26386C7.42505 2.24478 7.4216 2.22895 7.41897 2.21651L7.41565 2.20042L7.41443 2.19431L7.41392 2.19175L7.4137 2.19059C7.41359 2.19005 7.41349 2.18952 8.39535 1.99992C9.37721 1.81033 9.37712 1.80982 9.37702 1.80933L9.37684 1.80842L9.37653 1.80682L9.37608 1.80456C9.37593 1.80383 9.37586 1.80347 9.37586 1.80347L9.37591 1.80373L9.37874 1.81646C9.38213 1.83121 9.38861 1.85824 9.39891 1.8965C9.41951 1.97304 9.45529 2.09417 9.51185 2.2513C9.62504 2.56575 9.82085 3.0226 10.1438 3.55444Z" fill="black"/>
                            </svg>
                            </Link>
                        </div>
                        {/**第1个大图**/
                            commonFun.isNull(this.state.eventData[0])
                                ? null
                                : <a href={commonFun.isNull(this.state.eventData[0]['href']) ? '/club/events/detail/'+this.state.eventData[0]['id'] : this.state.eventData[0]['href']} className={styles.firstEvent}>
                                    <div className={styles.firstEventImg}>
                                        <img src={this.state.curWindowWidth>768 ? this.state.eventData[0]['poster'] : (this.state.eventData[0]['posterm'] || this.state.eventData[0]['poster'])}  alt='' />
                                        {
                                            this.state.eventData[0]['status']
                                                ? <div className={`${styles.eventStatusBox} ${styles.eventStatusClose}`}>Closed</div>
                                                : <div className={`${styles.eventStatusBox} ${styles.eventStatusActive}`}>On-going</div>
                                        }
                                    </div>
                                </a>
                        }
                        <div className={styles.moreEvent}>
                            {
                                this.state.eventData.map((item,key)=>{
                                    if(key===0 && this.state.curWindowWidth>768){
                                        return null;
                                    }
                                    return <a href={commonFun.isNull(item['href']) ? '/club/events/detail/'+item['id'] : item['href']} key={`event_${key}`} className={styles.eventBox}>
                                        <div className={styles.eventBoxImg}>
                                            <img src={item['posterm']} alt='' />
                                            {
                                                item['status']
                                                    ? <div className={`${styles.eventStatusBox} ${styles.eventStatusClose}`}>Closed</div>
                                                    : <div className={`${styles.eventStatusBox} ${styles.eventStatusActive}`}>On-going</div>
                                            }
                                        </div>
                                        <div className={styles.eventTitleContainer}>
                                            <div className={styles.eventTitle}>{item['projectName']}</div>
                                            <div className={styles.eventDateTime}>{item['date']}</div>
                                        </div>
                                    </a>;
                                })
                            }
                        </div>

                    </div>
            }


            {/**播放器**/}
            <div>
                {this.state.player}
            </div>
        </div>
    }
}