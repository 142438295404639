import React, {Component} from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonFun from "../../../functions/commonFun";
import apiService from "../../../functions/apiService";
import styles from '../../css/creatorsClub/PageCreators.module.css'
import {Link} from "react-router-dom";
gsap.registerPlugin(ScrollToPlugin);
/**
 * Meet the Creators 列表
 */
export default class PageCreators extends Component {
    httpRequest=false;//是否已经进行HTTP请求，防止重复请求
    page=1;//当前翻页
    hasPage=false;//能否进行翻页，根据返回的数据进行判断
    limit=20;//每页数量
    canTrackingEnd=false;//能否发送页面结束追踪
    constructor(props) {
        super(props);
        this.state = {
            creatorsData:null,
            smoothMoveDistance:264,//图片滚动一次移动的距离(PC)
            curWindowWidth:commonFun.getWidth()
        }
    }

    componentDidMount() {
        if(!this.httpRequest){
            this.httpRequest = true;
            if(commonFun.isNull(commonFun.getGlobalData('ptoken'))){//无法获取ptoken
                let timerCount = 0;
                let pTimer = setInterval( ()=> {
                    timerCount++;
                    if (timerCount > 10) {//防止死循环
                        clearInterval(pTimer)
                    }
                    if(!commonFun.isNull(commonFun.getGlobalData('ptoken'))) {
                        clearInterval(pTimer)
                        apiService.getCreatorsBlogs({page:this.page,limit:this.limit},this.getCreatorsBlogs.bind(this));
                    }
                },1000);
            }else{
                apiService.getCreatorsBlogs({page:this.page,limit:this.limit},this.getCreatorsBlogs.bind(this));
            }

        }
        commonFun.setGlobalData('MainNavSelected',3);
        window.addEventListener('resize', this.handleResize.bind(this)); //监听窗口大小改变
        gsap.to(window, {duration: commonFun.pageDuration, scrollTo:0});//回到0高度

        setTimeout(() => {//追踪代码
            if(!this.canTrackingEnd){
                apiService.viewerTracking({page:'creators',type:'club',action:'start'});
                this.canTrackingEnd=true;
                apiService.clickTracking();//A标签点击追踪
            }
        },commonFun.validSeconds);

        commonFun.setSeoInfos("PageCreators","club","Meet the Creators","","")
    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.handleResize.bind(this));
        if(this.canTrackingEnd){
            apiService.viewerTracking({page:'creators',type:'club',action:'end'});
            this.canTrackingEnd=false;
        }
    }

    /**
     * 更新容器大小
     */
    handleResize(){
        this.setState({curWindowWidth:commonFun.getWidth()})
    }

    /**
     * 获取Creators及对应的Blogs数据（多用户多视频）
     * @param result
     */
    getCreatorsBlogs(result){
        if(!commonFun.isNull(result)){
            if(result.length===this.limit){
                this.hasPage = true;
            }else{
                this.hasPage = false;
            }
            if(this.page>1){//翻页数据，对数据进行合并
                let tmp = this.state.creatorsData;
                for(let i=0;i<result.length;i++){
                    tmp.push(result[i]);
                }
                result = tmp;
                tmp = null;
            }
            this.setState({creatorsData:result});
        }
    }

    /**
     * 加载数据
     */
    loadMore(){
        this.page += 1;
        apiService.getCreatorsBlogs({page:this.page,limit:this.limit},this.getCreatorsBlogs.bind(this));
    }

    /**
     * 创建滚动画册（PC）
     * @param cardName 画册名称
     * @param data 画册数据对象
     * @returns {JSX.Element}
     */
    createSmoothList(cardName,data={}){
        return <div className={styles.cardContainer} key={`creators_${data['id']}`}>
            {/*头像昵称区*/}
            <div className={styles.cardFirstRowContainer}>
                <div className={styles.cardFirstRowAvatarAndCountryContainer}>
                    <img className={styles.cardAvatar} src={data['avatar']} alt='' />
                    <div className={styles.cardNicknameContainer}>
                        <div className={styles.cardNickname}>
                            {data['name']} <img src={data['badge']} alt='' />
                        </div>
                        <div className={styles.cardCountry}>
                            <img src='https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/detail/explore/explore-icon-country.svg' alt='' />
                            {data['country']}
                        </div>
                    </div>
                </div>
                <Link to={"/club/creators/blogs/"+data['id']} className={styles.cardPorfolioContainer}>
                    <div className={styles.cardPorfolioText}>View Porfolio</div>
                    <img className={styles.cardPorfolioArrow} src='https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/detail/explore/explore-icon-arrow-right.svg' alt='' />
                </Link>
            </div>

            <div className={styles.cardSecondRowOutContainer}>
                <div className={styles.arrowLeft} onClick={()=>{this.onSmoothLeft(cardName)}}>
                    <img src='https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/detail/explore/explore-icon-left.svg' alt='' />
                </div>
                {/*图片滚动区*/}
                <div className={`${styles.cardSecondRowContainer} ${cardName}`}>
                    {
                        data['blogs'].map((item,key)=>{
                            return <a className={styles.cardSecondItem} key={`blogs_${item['id']}_${key}`} href={item['href']} target="_blank" rel="noreferrer"
                                      style={{backgroundImage:`url(${item['poster']})`}}>
                                <div className={styles.coverShade}><div className={styles.cardSecondItemTitle}>{item['title']}</div></div>
                            </a>
                        })
                    }
                </div>
                <div className={`${styles.arrowRight} ${data['blogs'].length>4 && commonFun.getWidth()>768 ? styles.arrowShow : null}`} onClick={()=>{this.onSmoothRight(cardName)}}>
                    <img src='https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/detail/explore/explore-icon-right.svg' alt='' />
                </div>
            </div>

        </div>;
    }

    /**
     * 创建滚动画册（MOBILE）
     * @param data 对象
     * @returns {JSX.Element}
     */
    createSmoothListMobile(data={}){
        return <div className={styles.cardContainerMobile} key={`creators_${data['id']}`}>
            <div className={styles.cardAvatarContainerMobile}>
                <img className={styles.cardAvatarMobile}  src={data['avatar']} alt='' />
            </div>
            <div className={styles.cardNicknameOutContainerMobile}>
                <div className={styles.cardNicknameContainerMobile}>
                    {data['name']}
                    <img src={data['badge']} alt='' />
                </div>
                <div className={styles.cardCountryContainerMobile}>
                    <img src='https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-icon-country.svg' alt='' />
                    {data['country']}
                </div>
            </div>
            <div className={styles.cardSecondRowContainerMobile}>
                {this.createImgCard(data['blogs'])}
            </div>
            <Link to={"/club/creators/blogs/"+data['id']}  className={styles.cardViewMoreContainer}>
                <div className={styles.cardViewMoreText}>View More</div>
                <img className={styles.cardPorfolioArrow} src='https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/detail/explore/explore-icon-arrow-right.svg' alt='' />
            </Link>
        </div>
    }

    /**
     * 根据给定数据创建列表，单个、两个、多个带滚动的
     * @param data 数组
     * @returns {JSX.Element|null}
     */
    createImgCard(data=[]){
        if(commonFun.isNull(data)){
            return null;
        }
        if(data.length===1){//单个
            return <a className={styles.cardSecondSingleItemMobile} key={`blogs_${data[0]['id']}`} href={data[0]['href']} target='_blank' rel="noreferrer"
                        style={{backgroundImage:`url(${data[0]['posterm'] || data[0]['poster']})`}}>
                <div className={styles.coverShade} style={{width:'100%'}}><div className={styles.cardSecondMultiItemTitleMobile}>{data[0]['title']}</div></div>
                {/*<div className={styles.cardSecondSingleItemTitleMobile}>{data[0]['title']}</div>*/}
            </a>;
        }else if(data.length===2){//两个
            return <>
                <a className={styles.cardSecondDoubleItemMobile} key={`blogs_${data[0]['id']}_1`} href={data[0]['href']} target='_blank' rel="noreferrer"
                     style={{backgroundImage:`url(${data[0]['posterm'] || data[0]['poster']})`,marginRight:8}}>
                    <div className={styles.coverShade} style={{width:'100%'}}><div className={styles.cardSecondMultiItemTitleMobile}>{data[0]['title']}</div></div>
                    {/*<div className={styles.cardSecondDoubleItemTitleMobile}>{data[0]['title']}</div>*/}
                </a>
                <a className={styles.cardSecondDoubleItemMobile} key={`blogs_${data[1]['id']}_2`} href={data[1]['href']} target='_blank' rel="noreferrer"
                     style={{backgroundImage:`url(${data[1]['posterm'] || data[1]['poster']})`}}>
                    <div className={styles.coverShade} style={{width:'100%'}}><div className={styles.cardSecondMultiItemTitleMobile}>{data[1]['title']}</div></div>
                    {/*<div className={styles.cardSecondDoubleItemTitleMobile}>{data[1]['title']}</div>*/}
                </a>
            </>;
        }else{//多个带滚动
            return <>
                {
                    data.map((item,key)=>{
                        return <a className={styles.cardSecondMultiItemMobile} key={`blogs_${item['id']}_${key}`} href={item['href']} target='_blank' rel="noreferrer"
                                    style={{backgroundImage:`url(${item['posterm'] || item['poster']})`}}>
                            <div className={styles.coverShade}><div className={styles.cardSecondMultiItemTitleMobile}>{item['title']}</div></div>
                            {/*<div className={styles.cardSecondMultiItemTitleMobile}>{item['title']}</div>*/}
                        </a>
                    })
                }
            </>;
        }
    }

    /**
     * 向左边滚动
     * @param cardName 当前滚动的卡片名称
     * @returns {boolean}
     */
    onSmoothLeft(cardName){
        if(commonFun.isNull(cardName)){
            return false;
        }
        let offset = document.querySelector('.'+cardName).scrollLeft;
        gsap.to('.'+cardName, {duration: 0.1, scrollTo:{x:offset-this.state.smoothMoveDistance}});
        document.querySelector('.'+cardName).parentNode.querySelector('.'+styles.arrowRight).classList.remove(styles.arrowHide);//显示右边按钮
        document.querySelector('.'+cardName).parentNode.querySelector('.'+styles.arrowRight).classList.add(styles.arrowShow);//显示右边按钮
        if(offset<this.state.smoothMoveDistance){//隐藏左边按钮
            document.querySelector('.'+cardName).parentNode.querySelector('.'+styles.arrowLeft).classList.remove(styles.arrowShow);//隐藏左边按钮
            document.querySelector('.'+cardName).parentNode.querySelector('.'+styles.arrowLeft).classList.add(styles.arrowHide);//隐藏左边按钮
        }
        // console.log('left click offset='+offset)
    }

    /**
     * 向右边滚动
     * @param cardName 当前滚动的卡片名称
     * @returns {boolean}
     */
    onSmoothRight(cardName){
        if(commonFun.isNull(cardName)){
            return false;
        }
        let offset = document.querySelector('.'+cardName).scrollLeft;
        gsap.to('.'+cardName, {duration: 0.1, scrollTo:{x:offset+this.state.smoothMoveDistance}});
        document.querySelector('.'+cardName).parentNode.querySelector('.'+styles.arrowLeft).classList.add(styles.arrowShow);//显示左边按钮
        document.querySelector('.'+cardName).parentNode.querySelector('.'+styles.arrowLeft).classList.remove(styles.arrowHide);//显示左边按钮
        if(offset>=(document.querySelector('.'+cardName).scrollWidth-(this.state.smoothMoveDistance*5))){
            document.querySelector('.'+cardName).parentNode.querySelector('.'+styles.arrowRight).classList.add(styles.arrowHide);//隐藏右边按钮
            document.querySelector('.'+cardName).parentNode.querySelector('.'+styles.arrowRight).classList.remove(styles.arrowShow);//隐藏右边按钮
        }
        // console.log('right click offset='+offset)
    }

    render() {
        return commonFun.isNull(this.state.creatorsData) ? null : <div className={styles.pageContainer}>
            {
                commonFun.isNull(this.state.creatorsData)
                    ? null
                    : <div className={styles.pageContainerTopBackground}>
                    </div>
            }
                {/*Creators*/}
                <div className={styles.creatorContainer}>
                    <div className={styles.creatorBigTitleContainer}>
                        <div className={styles.creatorBigTitle}>Meet the Creators</div>
                    </div>
                    {
                        this.state.curWindowWidth > 768 ? this.state.creatorsData.map((item,key)=>{
                            // console.log('key='+key);
                            return this.createSmoothList(`cardName_${key}`,item);
                        }) : this.state.creatorsData.map((item,key)=>{
                            if(key===0){
                                return this.createSmoothListMobile(item)
                            }else if(key===1){
                                return this.createSmoothListMobile(item)
                            }else{
                                return this.createSmoothListMobile(item)
                            }
                        })
                    }

                </div>

                {
                    this.hasPage
                        ? <div className={styles.loadMore} onClick={()=>{this.loadMore()}}>Load More</div>
                        : null
                }
        </div>
    }
}