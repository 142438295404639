import React from 'react';
import ReactDOM from 'react-dom/client';
import './app.css';
import reportWebVitals from './reportWebVitals';
import commonFun from "./functions/commonFun";
import PageLayout from "./pages/jsx/PageLayout";
import apiService from "./functions/apiService";
commonFun.getConfig();
apiService.getMainNav();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <PageLayout />
    </React.StrictMode>
);
reportWebVitals();
