import React, {Component} from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonFun from "../../../functions/commonFun";
import apiService from "../../../functions/apiService";
import styles from '../../css/creatorsClub/PageCreatorsBlogs.module.css'
// import {Link} from "react-router-dom";
gsap.registerPlugin(ScrollToPlugin);
/**
 * Meet the Creators 列表
 */
export default class PageCreatorsBlogs extends Component {
    httpRequest=false;//是否已经进行HTTP请求，防止重复请求
    page=1;//当前翻页
    hasPage=false;//能否进行翻页，根据返回的数据进行判断
    limit=20;//每页数量
    canTrackingEnd=false;//能否发送页面结束追踪
    constructor(props) {
        super(props);
        this.state = {
            id:commonFun.getUrlParams('','/',6),//event id
            creatorData:null,
            curWindowWidth:commonFun.getWidth()
        }
    }

    componentDidMount() {
        if(!this.httpRequest){
            this.httpRequest = true;
            if(commonFun.isNull(commonFun.getGlobalData('ptoken'))){//无法获取ptoken
                let timerCount = 0;
                let pTimer = setInterval( ()=> {
                    timerCount++;
                    if (timerCount > 10) {//防止死循环
                        clearInterval(pTimer)
                    }
                    if(!commonFun.isNull(commonFun.getGlobalData('ptoken'))) {
                        clearInterval(pTimer)
                        apiService.getCreatorBlogsList({creator_id:this.state.id,page:this.page,limit:this.limit},this.getCreatorBlogsList.bind(this));
                    }
                },1000);
            }else{
                apiService.getCreatorBlogsList({creator_id:this.state.id,page:this.page,limit:this.limit},this.getCreatorBlogsList.bind(this));
            }
        }
        commonFun.setGlobalData('MainNavSelected',3);
        window.addEventListener('resize', this.handleResize.bind(this)); //监听窗口大小改变
        gsap.to(window, {duration: commonFun.pageDuration, scrollTo:0});//回到0高度
        // console.log("creator id="+this.state.id);

        setTimeout(() => {//追踪代码
            if(!this.canTrackingEnd){
                apiService.viewerTracking({page:'creator_blogs',type:'club',action:'start'});
                this.canTrackingEnd=true;
                apiService.clickTracking();//A标签点击追踪
            }
        },commonFun.validSeconds);
    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.handleResize.bind(this));
        if(this.canTrackingEnd){
            apiService.viewerTracking({page:'creator_blogs',type:'club',action:'end'});
            this.canTrackingEnd=false;
        }
    }

    /**
     * 更新容器大小
     */
    handleResize(){
        this.setState({curWindowWidth:commonFun.getWidth()})
    }

    /**
     * 获取创作者信息及Blogs数据（单用户多视频）
     * @param result
     */
    getCreatorBlogsList(result){
        // console.log('getCreatorBlogsList',result);
        if(!commonFun.isNull(result)){
            if(result['blogs'].length===this.limit){
                this.hasPage = true;
            }else{
                this.hasPage = false;
            }
            if(this.page>1){//翻页数据，对数据进行合并
                let tmp = this.state.creatorData['blogs'];
                for(let i=0;i<result['blogs'].length;i++){
                    tmp.push(result['blogs'][i]);
                }
                result['blogs'] = tmp;
                tmp = null;
            }
            this.setState({creatorData:result});
            commonFun.setSeoInfos("PageCreatorsBlogs","club",result['name'] + "'s Blog","","")
        }
    }

    /**
     * 加载数据
     */
    loadMore(){
        this.page += 1;
        apiService.getCreatorBlogsList({creator_id:this.state.id,page:this.page,limit:this.limit},this.getCreatorBlogsList.bind(this));
    }


    render() {
        return commonFun.isNull(this.state.creatorData) ? null : <div className={styles.pageContainer}>
            {
                commonFun.isNull(this.state.creatorData)
                    ? null
                    : <div className={styles.pageContainerTopBackground}>
                    </div>
            }
            {/*CreatorBlogs*/}
            <div className={styles.creatorBlogsContainer}>
                {/*用户信息区域*/}
                <div className={styles.creatorInfoContainer}>
                    <img src={this.state.creatorData['avatar']} className={styles.creatorAvatar} alt='' />

                    <div className={styles.creatorNickname}>
                        {this.state.creatorData['name']}
                        {
                            commonFun.isNull(this.state.creatorData['badge']) ? null : <img src={this.state.creatorData['badge']} alt='' />
                        }
                    </div>

                    <div className={styles.creatorCountry}>
                        <img src="https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-icon-country.svg" alt='' />
                        {this.state.creatorData['country']}
                        <img src="https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-icon-link-m.svg" alt='' />
                        <a href={this.state.creatorData['social']} rel="noreferrer" target="_blank">{commonFun.replaceAll(this.state.creatorData['social'],["http://","https://","www."],"")}</a>
                    </div>

                    {
                        commonFun.isNull(this.state.creatorData['tags']) ? null : <div className={styles.creatorTagsContainer}>
                            {
                                this.state.creatorData['tags'].split(",").map((v,k)=>{
                                    return <div key={`tag_${k}`} className={styles.creatorTags}>{v}</div>
                                })
                            }
                        </div>
                    }

                    <div className={styles.creatorDesc}>
                        {this.state.creatorData['content']}
                    </div>

                    {/*{*/}
                    {/*    commonFun.isNull(commonFun.getContactImg(this.state.creatorData['social'])) ? null : <div className={styles.creatorSocial}>*/}
                    {/*        <a href={this.state.creatorData['social']}><img src={commonFun.getContactImg(this.state.creatorData['social'])} alt='' /></a>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>

                {/*大标题*/}
                <div className={styles.creatorBigTitleContainer}>
                    <div className={styles.creatorBigTitle}>Works</div>
                </div>

                {/*用户作品列表容器*/}
                <div className={styles.worksListContainer}>
                    {
                        commonFun.isNull(this.state.creatorData['blogs']) ? null : this.state.creatorData['blogs'].map((item,key)=>{
                            return <a className={styles.worksListItem} key={'works_'+key} href={item['href']} target="_blank" rel='noreferrer'>
                                <img className={styles.worksPoster} src={commonFun.getWidth()>768 ? item['poster'] : (item['posterm'] || item['poster'])} alt='' />
                                <div className={styles.worksDeviceAndTitleContainer}>
                                    {
                                        commonFun.isNull(item['device']) ? null : <div className={styles.worksDeviceContainer}>
                                            <div className={styles.worksDevice}>{item['device']}</div>
                                        </div>
                                    }
                                    <div className={styles.worksTitle}>{item['title']}</div>
                                </div>
                            </a>
                        })
                    }
                </div>

            </div>

            {
                this.hasPage
                    ? <div className={styles.loadMore} onClick={()=>{this.loadMore()}}>Load More</div>
                    : null
            }
        </div>
    }
}