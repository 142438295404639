import md5 from "md5";
import axios from "axios";
// import React from "react";

const prefix = 'explore';

/**
 * 启动追踪的时间，单位：ms
 * @type {number}
 */
const validSeconds = 2000;

/**
 * 缓存过期时间
 * @type {number}
 */
const cacheExpire = 86400000;

/**用来设置全局变量**/
const globalData = {}

/**页面滚动时间**/
const pageDuration = 0.3;//0.3秒

/**
 * 网络请求拦截器
 * @param chain
 * @returns {*}
 */
// const interceptor = function (chain) {
//     const requestParams = chain.requestParams
//     console.log('all',requestParams)
//     const { method, data, url } = requestParams
//     console.log(`http ${method || 'GET'} --> ${url} data: `, data)
//     return chain.proceed(requestParams)
//         .then(res => {
//             console.log(`http <-- ${url} result:`, res)
//             return res
//         })
// }

// // 请求拦截器
// axios.interceptors.request.use(function (config) {//正常响应
//     console.log("=================interceptors.request=================",config)
//     // Do something before request is sent
//     return config;
// }, function (error) {//请求错误
//     console.log("=================interceptors.request.error=================",error)
//     // Do something with request error
//     return Promise.reject(error);
// });
//
// // 响应拦截器
// axios.interceptors.response.use(function (response) {//正常响应
//     console.log("=================interceptors.response=================",response)
//     // Do something with response data
//     return response;
// }, function (error) {//响应错误
//     console.log("=================interceptors.response.error=================",error)
//     // Do something with response error
//     return Promise.reject(error);
// });

/**
 * 获取全局变量
 * @param key
 * @return {*}
 */
function getGlobalData(key){
    let val = sessionStorage.getItem(key);// 使用会话
    if(!isNull(val)){
        val = JSON.parse(val);
    }else{
        val = '';
    }
    return val
}

/**
 * 设置全部变量
 * @param key
 * @param val
 */
function setGlobalData(key, val) {
    sessionStorage.setItem(key,JSON.stringify(val));// 使用会话
}

/**
 * 清除缓存
 */
function clearGlobalData(){
    // sessionStorage.clear();// 使用会话
    for(let k in globalData){//使用内存
        delete globalData[k];
    }
}

/**
 * 判断指定变量是否为空
 * 几种条件：str为{"",undefined,"null",null,false,str=object&&str.length=0}
 * @param str
 * @returns {boolean}
 */
function isNull(str) {
    if (typeof str === "undefined" || str === "null" || str === "" || str === " " || str === null || str === false || (typeof str === "object" && str.length===0)) {
        return true;
    }
    return false;
}

/**
 * 判断是否苹果设置
 * @returns
 */
function isIos () {
    let userAgentInfo = navigator.userAgent;
    let Agents = ["iPhone","iPad", "iPod","ios"];
    let flag = false;
    for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = true;
            break;
        }
    }
    // console.log('isIos='+flag);
    return flag;
}

/**
 * 获取当前页面的语言类型
 * @return {string}
 */
function getLanguage() {
    let language = isNull(document.querySelector('meta[name="language"]'))
        ? (isNull(document.querySelector("html")) ? 'cn' : document.querySelector("html").getAttribute('lang'))
        : document.querySelector('meta[name="language"]').content;
    if (isNull(language)) {
        language = 'cn';
    }
    return language;
}

/**
 * 格式化URL地址（给url添加域名）
 * @param url 需要处理的url，以斜杠开头，为url添加上域名
 * @param isAutoLang 是否自动添加语种
 * @returns {null|*}
 */
function formatUrl(url, isAutoLang = true) {
    if (isNull(url)) {
        return null;
    }
    let baseUrl = isNull(getGlobalData('baseUrl')) ? `https://${prefix}.zhiyun-tech.com` : getGlobalData('baseUrl');
    // if (url.substring(0, 4) != 'http') {
    //     url = isAutoLang ? (baseUrl + '/' + getLanguage() + url) : (baseUrl + url)
    // }
    if (url.substring(0, 4) !== 'http') {
        url = isAutoLang ? (baseUrl + '/' + getLanguage() + '/api' + url) : (baseUrl + '/api' + url)
    }
    return url;
}

/**
 * 检查URL是否为HTTP地址
 * @param url url地址
 * @returns {boolean}
 */
function isHttp(url){
    if (isNull(url)) {
        return false;
    }
    if (url.substring(0, 4) !== 'http') {
        return true;
    }
    return false;
}

/**
 * 获取签名串
 * @param {string | object} params
 * @returns {string}
 */
function getSign(params) {
    if (typeof params == "string") {
        return this.paramsStrSort(params);
    } else if (typeof params == "object") {
        let arr = [];
        for (let i in params) {
            arr.push((i + "=" + params[i]));
        }
        return this.paramsStrSort(arr.join(("&")));
    }
}

/**
 * 对url串进行排序拼接
 * @param {string} paramsStr
 * @returns {string}
 */
function paramsStrSort(paramsStr) {
    let urlStr = paramsStr.split("&").sort().join("");
    return md5(urlStr);
}

/**
 * HTTP GET 请求
 * @param api API接口地址
 * @param data 请求查询{a=1,b=2}
 * @param feedback 请求结束后回调函数
 */
function requestGet(api,data={},feedback=function () {}) {
    if (isNull(api)) {
        feedback(false);
    }
    axios.get(formatUrl(api,false), {params: data})
        .then(function (response) {
            if(response.status===200){
                feedback(response.data);
            }else{
                feedback(false);
            }
        }).catch(function (error) {
        feedback({code:10000,message:error});
    });
}

/**
 * HTTP POST 请求
 * @param api API接口地址
 * @param data 请求查询{a=1,b=2}
 * @param feedback 请求结束后回调函数
 */
function requestPost(api,data={},feedback=function () {}) {
    if (isNull(api)) {
        feedback(false);
    }
    axios.post(formatUrl(api,false), data)
        .then(function (response) {
            if(response.status===200){
                feedback(response.data);
            }else{
                feedback(false);
            }
        }).catch(function (error) {
        feedback({code:10000,message:error});
    });
}

/**
 * 获取url中指定参数，参数通过斜杠传递
 * @param {string} url URL地址
 * @param {string} split 分隔符
 * @param {int} index 索引 从0开始,从http:// 上的斜杠开始计算
 * @returns {string}
 */
function getUrlParams (url='',split = "/", index = 0) {
    let query = isNull(url) ? (isNull(window.location.href) ? '' : window.location.href) : url;
    let vars = query.split(split);
    return vars[index] ?? '';
}

/**
 * 获取url中指定参数，参数通过问号传递
 * @param {string} url URL地址
 * @param {string} key 参数名称，如：pid
 * @returns {string}
 */
function getQueryVariable (url='',key='pid') {
    let query = isNull(url) ? (isNull(window.location.search) ? '' : window.location.search.substring(1)) : url;
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] === key) {
            return pair[1] ?? '';
        }
    }
    return '';
}

/**
 * 产生随机数
 * @param {number} len 长度，默认32位
 * @returns {String}
 */
function randomString(len) {
    len = len || 32;
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

/**
 * 创建并返回uuid，仅供标识使用
 * @returns string uuid
 */
function createCUUID() {
    let cuuid;
    let infos = getGlobalData('CUUID');
    if (isNull(infos)) {
        let randstr = randomString(16);
        let timestamp = new Date().getTime();
        cuuid = randstr + timestamp;
        let obj = {uuid: cuuid, expire: (Date.now() + 86400000)};
        setGlobalData('CUUID',JSON.stringify(obj));
    } else {
        infos = getGlobalData('CUUID');
        if (isNull(infos)) {
            return '';
        }
        infos = JSON.parse(infos);
        cuuid = infos.uuid;
    }
    return cuuid;
}

/**
 * 判断是否微信浏览器
 * @returns {Boolean}
 */
function isWeixin () {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) === "micromessenger") {
        return true;
    } else {
        return false;
    }
}

/**
 * 浏览器名称+版本
 * @return {string}
 */
function getBrowser () {
    let broName = 'unknow';
    let userAgent = window.navigator.userAgent.toLowerCase(); // 包含以下属性中所有或一部分的字符串：appCodeName,appName,appVersion,language,platform
    if (userAgent.indexOf('firefox') !== -1) {// FireFox
        broName = 'Firefox';
    }else if (userAgent.indexOf('edge') !== -1) {// Edge
        broName = 'Edge';
    }else if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {// IE浏览器
        broName = 'IE';
    }else if (userAgent.indexOf('qqbrowser') !== -1) {// qqbrowser浏览器
        broName = 'QQbrowser';
    }else if (userAgent.indexOf('wow') !== -1 && userAgent.indexOf('net') < 0 && userAgent.indexOf('firefox') < 0) {// 360极速模式可以区分360安全浏览器和360极速浏览器
        broName = '360极速浏览器-极速模式';
    }else if (userAgent.indexOf('wow') !== -1 && userAgent.indexOf('net') !== -1 && userAgent.indexOf('msie') !== -1 && userAgent.indexOf('rv') < 0) {// 360兼容
        broName = '360兼容模式';
    }else if (userAgent.indexOf('safari') !== -1) {// Chrome浏览器
        broName = 'Safari';
    }else if (userAgent.indexOf('opera') !== -1) {// opera
        broName = 'Opera';
    }else if (userAgent.indexOf('micromessenger') !== -1) {// 微信浏览器
        broName = 'Micromessenger';
    }else if (userAgent.indexOf('chrome') !== -1) {// Chrome浏览器
        broName = 'Chrome';
    }
    return broName;
}

/**
 * 用户语言系统名称
 * @return {string}
 */
function getPlatform(){
    let ua = navigator.userAgent.toLowerCase();//浏览器
    let platform = navigator.platform.toLowerCase();
    if (ua.indexOf('iphone') !== -1) {
        platform =  'iphone';
    }else if (ua.indexOf('android') !== -1) {
        platform =  'android';
    }else if (ua.indexOf('windows') !== -1) {
        platform =  'windows';
    }else if (ua.indexOf('mac') !== -1) {
        platform =  'mac';
    }
    return  platform;
}

/**
 * 获取链接地址的域名
 * @param url
 * @return {*}
 */
function getDomain (url) {
    let domain = url.split('/');
    if( domain[2] ) {
        domain = domain[2];
    } else {
        domain = ''; //如果url不正确就取空
    }
    return domain;
}

/**
 * 获取页面宽度
 * @returns {*}
 */
function getWidth(){
    return document.querySelector('body').clientWidth;
}

/**
 * 字符串替换
 * @param {string} content 字符串
 * @param {string[]|string} FindText 查找的字符
 * @param {string} RepText 替换的字符
 * @returns {common@call;replace}
 */
function replaceAll (content="",FindText="\n", RepText="<br/>") {
    if(typeof FindText == "object"){
        for (let key in FindText) {
            content=content.replace(new RegExp(FindText[key].replace(/([\(\)\[\]\{\}\^\$\+\-\*\?\.\"\'\|\/\\])/g, "\\$1"), "ig"), RepText);
        }
        return content;
    }
    return content.replace(new RegExp(FindText.replace(/([\(\)\[\]\{\}\^\$\+\-\*\?\.\"\'\|\/\\])/g, "\\$1"), "ig"), RepText);
}

/**
 * 获取官网配置信息，在页面中使用。
 * 在页面的 componentDidMount 中调用
 * 注意：不建议在组件中使用，在一个页面引用多个组件时，会导致一个页面重复请求的问题
 */
function getConfig(feedback=function () {}){
    let api = `https://${prefix}.zhiyun-tech.com/${getLanguage()}/api/common/get_config`
    requestPost(api,{session:createCUUID()},function (result) {
        if(result.code===0){
            if(!isNull(result.cache) && result.cache===1){
                clearGlobalData();
            }
            setGlobalData('ptoken', result['ptoken']);//表单提交时必
            // setGlobalData('token', result['token']);//进行xsrf验证使用
            setGlobalData('baseUrl', result['web_api']);//设置网页地址头部
            // setGlobalData('apiHttp', result['web_api']);//API接口地址头部
            setGlobalData('analysisApi', result['analysis_api']);//分析系统地址
            feedback();
        }
    });
}

function timestampToTime(timestamp) {
    let date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    let D = date.getDate() + ' ';
    let h = date.getHours() + ':';
    let m = date.getMinutes() + ':';
    let s = date.getSeconds();
    return Y+M+D+h+m+s;
}

/**
 * 格式化日期
 * @param {string | number | Date} value 指定日期
 * @param {string} format 格式化的规则
 * @param {boolean} toGMT 是否转换为GMT时间格式
 * @example
 * ```js
 * formatDate();
 * formatDate(1603264465);
 * formatDate(1603264465, "h:m:s");
 * formatDate(1603264465, "Y年M月D日");
 * ```
 */
function formatDate(value = Math.round(Date.now()/1000), format = "Y-M-D h:m:s",toGMT=false) {
    let formatNumber = n => `0${n}`.slice(-2);
    let date = new Date(value*1000);
    let formatList = ["Y", "M", "D", "h", "m", "s"];
    let resultList = [];
    resultList.push(date.getFullYear().toString());
    resultList.push(formatNumber(date.getMonth() + 1));
    resultList.push(formatNumber(date.getDate()));
    resultList.push(formatNumber(date.getHours()));
    resultList.push(formatNumber(date.getMinutes()));
    resultList.push(formatNumber(date.getSeconds()));
    for (let i = 0; i < resultList.length; i++) {
        format = format.replace(formatList[i], resultList[i]);
    }
    if(toGMT){
        format = new Date(format).toGMTString();
        format = replaceAll(format,"00:00:00 GMT","");
    }
    return format;
}

/**
 * 根据URL地址，返回社交平台的LOGO地址
 * @param url
 * @returns {string}
 */
function getSocialImg(url=''){
    if(isNull(url)){
        return '';
    }
    const socials={
        'zhiyun-tech':'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/images/20220726/1658827921503-WhMMKFJs.jpg',
        'facebook':'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/social/facebook.svg',
        'instagram':'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/social/instagram.svg',
        'linkedin':'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/social/linkedin.svg',
        'tiktok':'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/social/tiktok.svg',
        'youtube':'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/social/youtube.svg'
    };
    let img = '';
    for (let key in socials){
        if(url.indexOf(key) !== -1){
            img = socials[key];
            break;
        }
    }
    return img;
}

/**
 * 根据URL地址，返回社交平台的LOGO地址,个人资料
 * @param url
 * @returns {string}
 */
function getContactImg(url=''){
    if(isNull(url)){
        return '';
    }
    const socials={
        'zhiyun-tech':'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/images/20220726/1658827921503-WhMMKFJs.jpg',
        'instagram':'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-logo-instagram-color.svg',
        'youtube':'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-logo-youtube-color.svg'
    };
    let img = '';
    for (let key in socials){
        if(url.indexOf(key) !== -1){
            img = socials[key];
            break;
        }
    }
    return img;
}

/**
 * 设置页面及SEO信息
 * @param page 页面名称 index
 * @param type 页面类型(模块) club
 * @param title 页面标题
 * @param keywords SEO关键字
 * @param content SEO内容
 */
function setSeoInfos(page="",type="",title="",keywords="",content="") {
    let domPage = document.querySelector('meta[name="page"]')
    let domType = document.querySelector('meta[name="type"]')
    let domTitle = document.querySelector("title")
    let domKeywords = document.querySelector('meta[name="keywords"]')
    let domContent = document.querySelector('meta[name="content"]')
    domPage?.setAttribute("content",page)
    domType?.setAttribute("content",type)
    domTitle.innerHTML = title
    domKeywords?.setAttribute("content",keywords)
    domContent?.setAttribute("content",content)
}

/**
 * 检查邮箱是否正确
 * @param mail
 * @returns {boolean}
 */
function isEmail (mail){
    let reg = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/
    return reg.test(mail);
}

export default {
    validSeconds,cacheExpire,pageDuration,randomString,createCUUID,replaceAll,timestampToTime,formatDate,
    isWeixin,isNull,isIos,getWidth,isHttp,isEmail,
    requestGet,requestPost,getConfig,getUrlParams,getQueryVariable,getBrowser,getPlatform,getDomain,getSocialImg,getContactImg,
    getLanguage,formatUrl,getSign,paramsStrSort,
    setGlobalData,getGlobalData,clearGlobalData,setSeoInfos
}
