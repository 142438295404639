import React, {Component} from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonFun from "../../../functions/commonFun";
import styles from '../../css/creatorsClub/PageRulePolicy.module.css'
import apiService from "../../../functions/apiService";
gsap.registerPlugin(ScrollToPlugin);

/**
 * 条款协议
 */
export default class PageRulePolicy extends Component {
    httpRequest=false;//是否已经进行HTTP请求，防止重复请求
    canTrackingEnd=false;//能否发送页面结束追踪
    constructor(props) {
        super(props);
        this.state = {
            policyData:''
        }
    }

    componentDidMount() {
        commonFun.setGlobalData('MainNavSelected',-1);

        if(!this.httpRequest){
            this.httpRequest = true;
            if(commonFun.isNull(commonFun.getGlobalData('ptoken'))){//无法获取ptoken
                let timerCount = 0;
                let pTimer = setInterval( ()=> {
                    timerCount++;
                    if (timerCount > 10) {//防止死循环
                        clearInterval(pTimer)
                    }
                    if(!commonFun.isNull(commonFun.getGlobalData('ptoken'))) {
                        clearInterval(pTimer)
                        apiService.getClubPolicy(this.getClubPolicy.bind(this));
                    }
                },1000);
            }else{
                apiService.getClubPolicy(this.getClubPolicy.bind(this));
            }
        }

        gsap.to(window, {duration: commonFun.pageDuration, scrollTo:0});//回到0高度

        setTimeout(() => {//追踪代码
            if(!this.canTrackingEnd){
                apiService.viewerTracking({page:'policy',type:'club',action:'start'});
                this.canTrackingEnd=true
                apiService.clickTracking();//A标签点击追踪
            }
        },commonFun.validSeconds);

        commonFun.setSeoInfos("PageRule","club","policy","","")
    }

    componentWillUnmount() {
        if(this.canTrackingEnd){
            apiService.viewerTracking({page:'policy',type:'club',action:'end'});
            this.canTrackingEnd=false;
        }
    }

    /**
     * 获取Policy数据
     * @param result
     */
    getClubPolicy(result){
        if(!commonFun.isNull(result)){
            if(result['code']===0){
                this.setState({policyData:result['data']})
            }
        }
    }

    render() {
        return <div className={styles.pageContainer}>
            {/*大标题*/}
            <div className={styles.topContainer}>
                <div className={styles.topText}>Privacy Policy</div>
            </div>

            {/*卡片2内容*/}
            <div className={`${styles.cardContainer}`}>
                <div dangerouslySetInnerHTML={{__html:this.state.policyData['content']}}></div>
            </div>

        </div>
    }
}