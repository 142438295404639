import React, {Component} from 'react';
import commonFun from "../functions/commonFun";
import styles from './MainNav.module.css'
import {Link} from "react-router-dom";
import apiService from "../functions/apiService";

/**
 * 头部一级导航菜单
 * @param userInfo 用户信息 {uid,avatar}
 */
export default class MainNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainNavConfig:null,//commonFun.getGlobalData('mainNavConfig'),//导航配置
            userInfo:props.userInfo ?? {},//用户信息
            // adInfo:props.adInfo ?? commonFun.isNull(commonFun.getGlobalData('mainNavInfo')) ? {"link":"","text":"CREATORS WANTED | Get ZHIYUN CRANE-M3 for FREE!"} : commonFun.getGlobalData('mainNavInfo'),//广告信息
            // selected:props.selected ?? commonFun.isNull(commonFun.getGlobalData('MainNavSelected')) ? 2 : commonFun.getGlobalData('MainNavSelected'),//被选择的菜单
            selected:-1,//被选择的菜单
            dSelected:-1,//props.dSelected ?? commonFun.isNull(commonFun.getGlobalData('MainNavDSelected')) ? 10 : commonFun.getGlobalData('MainNavDSelected') ,//抽屉被选中的菜单
            dOpen:false,//抽屉菜单是否被打开
        }
        // console.log("Log="+commonFun.getUrlParams('http://localhost:3000/','/',2));
    }

    componentDidMount() {
        apiService.getMainNav();

        let timer = setInterval(()=>{//更新storage数据，不可删除
            if(!commonFun.isNull(commonFun.getGlobalData('mainNavConfig'))){
                this.setState({mainNavConfig:commonFun.getGlobalData('mainNavConfig')});
                clearInterval(timer);
            }
        },300);

        setInterval(()=>{//导航菜单颜色切换
            this.setState({selected:commonFun.getGlobalData('MainNavSelected'),dSelected:commonFun.getGlobalData('MainNavDSelected')});
        },500);
    }


    /**
     * 点击主导航菜单
     * @param index
     */
    navClickHandler(index = 0) {
        this.setState({selected: index});
        commonFun.setGlobalData('MainNavSelected',index)
    }

    /**
     * 点击右上角打开/关闭抽屉按钮
     * @param e
     */
    drawerOpenClick(e){
        let drawerOutContainer = document.querySelector('.'+styles.drawerOutContainer);
        let drawerContainer = document.querySelector('.'+styles.drawerContainer);
        if(this.state.dOpen){//关闭
            setTimeout(()=>{
                this.setState({dOpen: false});
            },300)
            drawerContainer.classList.add(styles.drawerContainerClose);
            drawerContainer.classList.remove(styles.drawerContainerOpen);

            e.target.classList.add(styles.drawerBtnClose);
            e.target.classList.remove(styles.drawerBtnOpen);

            drawerOutContainer.classList.remove(styles.drawerOutContainerOpen);//遮罩背景
        }else{//打开
            this.setState({dOpen: true});
            drawerContainer.classList.add(styles.drawerContainerOpen);
            drawerContainer.classList.remove(styles.drawerContainerClose);

            e.target.classList.add(styles.drawerBtnOpen);
            e.target.classList.remove(styles.drawerBtnClose);

            drawerOutContainer.classList.add(styles.drawerOutContainerOpen);//遮罩背景
        }
        let svgBtn = document.querySelector('.'+styles.navStatusButton);
        let nodes = svgBtn.childNodes;
        if(svgBtn.getAttribute('status')==='open'){
            svgBtn.classList.remove(styles.navStatusButtonHover);
            nodes[0].classList.remove(styles.hamMediumHover);
            nodes[1].classList.remove(styles.hamTopHover);
            nodes[2].classList.remove(styles.hamTopHover);
            svgBtn.setAttribute('status','close');
        }else{
            svgBtn.classList.add(styles.navStatusButtonHover);
            nodes[0].classList.add(styles.hamMediumHover);
            nodes[1].classList.add(styles.hamTopHover);
            nodes[2].classList.add(styles.hamTopHover);
            svgBtn.setAttribute('status','open');
        }
    }

    /**
     * 点击抽屉导航
     * @param index
     */
    drawerClickerHandler(index=0){
        //this.setState({dSelected: index});
        commonFun.setGlobalData('MainNavDSelected',index)
    }

    render() {
        return commonFun.isNull(this.state.mainNavConfig) || commonFun.isNull(this.state.mainNavConfig['nav']) ? null :  <>
            <div className={styles.container}>
                <div className={styles.selection1}>
                    <div className={styles.selection1Left}>
                        <a href='https://www.zhiyun-tech.com/en'>
                            <img src="https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-logo-zhiyun.svg" alt="" />
                        </a>
                        <div className={styles.divide}>
                            <img src="https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/Vector 13.svg" alt="" />
                        </div>
                        <a href='/'>
                            <img src="https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-logo-explore.svg" alt="" />
                        </a>
                    </div>
                    <div className={styles.selection1Right}>
                        {/*{*/}
                        {/*    commonFun.isNull(this.state.mainNavConfig['nav']) ? null : this.state.mainNavConfig['nav'].map((item,k)=>{*/}
                        {/*        if(item['show']===0){*/}
                        {/*            return;*/}
                        {/*        }*/}
                        {/*        // return <a key={`mainNav_`+k} onClick={() => {this.navClickHandler(k)}}*/}
                        {/*        //           className={this.state.selected === k ? `${styles.selection1RightItem} ${styles.active}` : styles.selection1RightItem}*/}
                        {/*        //           href={commonFun.isNull(item['link']) ? '' : item['link']}>{item['name']}</a>*/}
                        {/*        return <Link key={`mainNav_`+k} onClick={() => {this.navClickHandler(k)}}*/}
                        {/*                     className={this.state.selected === k ? `${styles.selection1RightItem} ${styles.active}` : styles.selection1RightItem}*/}
                        {/*                     to={item['link']}>{item['name']}</Link>*/}
                        {/*    })*/}
                        {/*}*/}
                        <Link to="/club/events" onClick={() => {this.navClickHandler(0)}}
                              className={this.state.selected === 0 ? `${styles.selection1RightItem} ${styles.active}` : styles.selection1RightItem}>Events</Link>
                        <Link to="/club/projects" onClick={() => {this.navClickHandler(1)}}
                              className={this.state.selected === 1 ? `${styles.selection1RightItem} ${styles.active}` : styles.selection1RightItem}>CREATORS WANTED</Link>
                        <Link to="/club/features" onClick={() => {this.navClickHandler(2)}}
                              className={this.state.selected === 2 ? `${styles.selection1RightItem} ${styles.active}` : styles.selection1RightItem}>Featured Works</Link>
                        <Link to="/club/creators" onClick={() => {this.navClickHandler(3)}}
                              className={this.state.selected === 3 ? `${styles.selection1RightItem} ${styles.active}` : styles.selection1RightItem}>Meet the Creators</Link>
                        <Link to="/club/rule" onClick={() => {this.navClickHandler(4)}}
                              className={this.state.selected === 4 ? `${styles.selection1RightItem} ${styles.active}` : styles.selection1RightItem}>About&Rules</Link>
                        {/*<div className={styles.avatar}>*/}
                        {/*    <img*/}
                        {/*    src={commonFun.isNull(this.state.userInfo['avatar'])*/}
                        {/*        ? 'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/images/20220125/1643101911733-pckiGCwd.jpg'*/}
                        {/*        : this.state.userInfo['avatar']}/>*/}
                        {/*</div>*/}
                        <div className={styles.drawerBtn} id={styles.drawerBtn} onClick={(e)=>{this.drawerOpenClick(e)}}>
                            {/*<img src={this.state.dOpen ? 'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/close.png' : 'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/drawer.png'}/>*/}
                            <svg
                                className={styles.navStatusButton}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="white"
                                xmlns="http://www.w3.org/2000/svg"
                                status="close"
                            >
                                <path className={styles.hamMedium} d="M4 12L20 12"></path>
                                <path
                                    className={styles.hamTop}
                                    d="M20 5H4.5C2.567 5 1 6.567 1 8.5V8.5C1 10.433 2.567 12 4.5 12H20"
                                ></path>
                                <path
                                    className={styles.hamBottom}
                                    d="M4 19L19.5 19C21.433 19 23 17.433 23 15.5C23 13.567 21.433 12 19.5 12L4 12"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </div>
                {/*{*/}
                {/*    commonFun.isNull(this.state.mainNavConfig['adInfo']['data']) ? null : <a className={styles.selection2} href={this.state.mainNavConfig['adInfo']['data']['href']}>*/}
                {/*        <div className={styles.adBanner}>{this.state.mainNavConfig['adInfo']['data']['content']}</div>*/}
                {/*        <div className={styles.arrow}>→</div>*/}
                {/*    </a>*/}
                {/*}*/}
            </div>

            {/*抽屉菜单*/}
            <div className={styles.drawerOutContainer} onClick={()=>{document.getElementById(styles.drawerBtn).click()}}></div>
            <div className={styles.drawerContainer} onClick={()=>{document.getElementById(styles.drawerBtn).click()}}>
                {
                    commonFun.isNull(this.state.mainNavConfig['drawer']) ? null : this.state.mainNavConfig['drawer'].map((item,k)=>{
                        if(item['show']===0){
                            return null;
                        }
                        return item['type']==='main'
                            ? <a key={`drawerNav_`+k} onClick={()=>{this.drawerClickerHandler(k)}} className={this.state.dSelected === k ? `${styles.drawerTitle} ${styles.active}` : styles.drawerTitle}
                                 href={commonFun.isNull(item['link']) ? ' ' : item['link']} style={{display:"none"}}>
                                <div key={`drawerNav_name_`+k}>{item['name']}</div>
                                <div key={`drawerNav_arrow_`+k} className={styles.drawerArrow}>→</div>
                            </a>
                            : <a key={`drawerNav_`+k} onClick={()=>{this.drawerClickerHandler(k)}} className={this.state.dSelected === k ? `${styles.drawerItem} ${styles.active}` : styles.drawerItem}
                                 href={commonFun.isNull(item['link']) ? ' ' : item['link']}>{item['name']}</a>
                    })
                }
                <div className={styles.socialContainer}>
                    {
                        commonFun.isNull(this.state.mainNavConfig['social']) ? null : this.state.mainNavConfig['social'].map((item,k)=>{
                            if(item['show']===0){
                                return null;
                            }
                            return <a key={`mainNav_social_`+k} href={commonFun.isNull(item['link']) ? ' ' : item['link']}>
                                <div dangerouslySetInnerHTML={{__html:item['src']}}></div>
                            </a>
                        })
                    }
                </div>
            </div>

            {
                commonFun.isNull(this.state.mainNavConfig) || commonFun.isNull(this.state.mainNavConfig['adInfo']) || commonFun.isNull(this.state.mainNavConfig['adInfo']['data']) || commonFun.isNull(this.state.mainNavConfig['adInfo']['data']['content']) || !commonFun.isNull(commonFun.getUrlParams('','/',3))
                    ? null
                    : <a className={styles.adBannerContainer} href={this.state.mainNavConfig['adInfo']['data']['href']}>
                        <div className={styles.adBanner}>{this.state.mainNavConfig['adInfo']['data']['content']}</div>
                        <div className={styles.arrow}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1438 4.05444C10.7866 5.11305 11.9418 6.48415 13.9876 7.62688L15.5507 8.49992L13.9876 9.37296C11.9418 10.5157 10.7866 11.8868 10.1438 12.9454C9.82085 13.4772 9.62504 13.9341 9.51185 14.2485C9.45529 14.4057 9.41951 14.5268 9.39891 14.6034C9.38861 14.6416 9.38213 14.6686 9.37874 14.6834L9.37591 14.6961L9.37608 14.6953L9.37653 14.693L9.37684 14.6914L9.37702 14.6905C9.37712 14.69 9.37721 14.6895 8.39535 14.4999C7.41349 14.3103 7.41359 14.3098 7.4137 14.3093L7.41392 14.3081L7.41443 14.3055L7.41565 14.2994L7.41897 14.2833C7.4216 14.2709 7.42505 14.2551 7.42943 14.236C7.43819 14.1978 7.45066 14.1466 7.46764 14.0835C7.50159 13.9574 7.55362 13.7835 7.63006 13.5712C7.78287 13.1466 8.03389 12.5667 8.43424 11.9074C8.88488 11.1652 9.52203 10.3271 10.4139 9.49992H1V7.49992H10.4139C9.52203 6.67274 8.88488 5.83464 8.43424 5.09246C8.03389 4.43312 7.78287 3.85321 7.63006 3.42869C7.55362 3.21634 7.50159 3.04243 7.46764 2.91631C7.45066 2.85323 7.43819 2.80203 7.42943 2.76386C7.42505 2.74478 7.4216 2.72895 7.41897 2.71651L7.41565 2.70042L7.41443 2.69431L7.41392 2.69175L7.4137 2.69059C7.41359 2.69005 7.41349 2.68952 8.39535 2.49992C9.37721 2.31033 9.37712 2.30982 9.37702 2.30933L9.37684 2.30842L9.37653 2.30682L9.37608 2.30456C9.37593 2.30383 9.37586 2.30347 9.37586 2.30347L9.37591 2.30373L9.37874 2.31646C9.38213 2.33121 9.38861 2.35824 9.39891 2.3965C9.41951 2.47304 9.45529 2.59417 9.51185 2.7513C9.62504 3.06575 9.82085 3.5226 10.1438 4.05444Z" fill="white"/>
                            </svg>
                        </div>
                    </a>
            }
        </>
    }
}