import React, {Component} from 'react';
import { Typography, Space } from '@douyinfe/semi-ui';
// import { IconArrowLeft, IconArrowRight,IconChevronLeft,IconChevronRight } from "@douyinfe/semi-icons";
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonFun from "../../../functions/commonFun";
import apiService from "../../../functions/apiService";
import styles from '../../css/creatorsClub/PageFeatures.module.css'
import {Link} from "react-router-dom";
gsap.registerPlugin(ScrollToPlugin);
/**
 * Featured works 列表
 */
export default class PageFeatures extends Component {
    httpRequest=false;//是否已经进行HTTP请求，防止重复请求
    page=1;//当前翻页
    hasPage=false;//能否进行翻页，根据返回的数据进行判断
    limit=20;//每页数量
    canTrackingEnd=false;//能否发送页面结束追踪
    constructor(props) {
        super(props);
        this.state = {
            featureData:null,
            advertData:null,
            curWindowWidth:commonFun.getWidth()
        }
    }

    componentDidMount() {
        if(!this.httpRequest){
            this.httpRequest = true;
            if(commonFun.isNull(commonFun.getGlobalData('ptoken'))){//无法获取ptoken
                let timerCount = 0;
                let pTimer = setInterval( ()=> {
                    timerCount++;
                    if (timerCount > 10) {//防止死循环
                        clearInterval(pTimer)
                    }
                    if(!commonFun.isNull(commonFun.getGlobalData('ptoken'))) {
                        clearInterval(pTimer)
                        apiService.getFeaturesCategory({page:this.page,limit:this.limit},this.getFeaturesCategory.bind(this));
                        apiService.getClubLastAdvert({'position':'features','type':'video'},this.getFeaturesLastAdvert.bind(this));//一条视频
                    }
                },1000);
            }else{
                apiService.getFeaturesCategory({page:this.page,limit:this.limit},this.getFeaturesCategory.bind(this));
                apiService.getClubLastAdvert({'position':'features','type':'video'},this.getFeaturesLastAdvert.bind(this));//一条视频
            }
        }
        commonFun.setGlobalData('MainNavSelected',2);
        gsap.to(window, {duration: commonFun.pageDuration, scrollTo:0});//回到0高度

        setTimeout(() => {//追踪代码
            if(!this.canTrackingEnd){
                apiService.viewerTracking({page:'features',type:'club',action:'start'});
                this.canTrackingEnd = true;
                apiService.clickTracking();//A标签点击追踪
            }
        },commonFun.validSeconds);

        commonFun.setSeoInfos("PageFeatures","club","Featured Works","","")

        setTimeout(()=>{
            this.resizeCoverShade();
            window.addEventListener('resize',this.resizeCoverShade);
        },500)
    }

    // setTimeout(()=>{
    //     this.resizeCoverShade()
    // },500)


    // window.addEventListener("resize",function () {
    //     console.log(document.querySelector("."+styles.bannerVideo).offsetHeight)
    //     docuemnt.querySelector("#videoCoverShade").style.height=document.querySelector("."+styles.bannerVideo).offsetHeight+"px";
    // });


    resizeCoverShade(){
        // console.log(document.querySelector("."+styles.bannerVideo).offsetHeight)
        if(commonFun.isNull(document.querySelector("#videoCoverShade"))){
            return false;
        }
        document.querySelector("#videoCoverShade").style.height=document.querySelector("."+styles.bannerVideo).offsetHeight+"px";
        document.querySelector("."+styles.bannerContentContainer).style.height = document.querySelector("."+styles.bannerVideo).offsetHeight+"px"
    }

    componentWillUnmount() {
        if(this.canTrackingEnd){
            apiService.viewerTracking({page:'features',type:'club',action:'end'});
            this.canTrackingEnd=false;
        }
    }

    /**
     * 获取广告数据
     * @param result
     */
    getFeaturesLastAdvert(result){
        if(!commonFun.isNull(result)){
            this.setState({advertData:result})
        }
    }

    /**
     * 获取Features数据
     * @param result
     */
    getFeaturesCategory(result){
        if(!commonFun.isNull(result)){
            if(result.length===this.limit){
                this.hasPage = true;
            }else{
                this.hasPage = false;
            }
            if(this.page>1){//翻页数据，对数据进行合并
                let tmp = this.state.featureData;
                for(let i=0;i<result.length;i++){
                    tmp.push(result[i]);
                }
                result = tmp;
                tmp = null;
            }
            this.setState({featureData:result});
        }
    }

    /**
     * 获取adverts广告轮播数据
     * @param result
     */
    getAdvertData(result){
        if(!commonFun.isNull(result)){
            if(!commonFun.isNull(result)){
                this.setState({advertData:result});
                this.indicatorProcess(0,0);
            }
        }
    }


    /**
     * 加载数据
     */
    loadMore(){
        this.page += 1;
        apiService.getFeaturesCategory({page:this.page,limit:this.limit},this.getFeaturesCategory.bind(this));
    }

    /**
     * 轮播滚动条
     * @param index 当前滚动条位置
     * @param preIndex 上一个滚动条位置
     */
    indicatorProcess(index, preIndex){
        let dom = document.querySelector('.semi-carousel-indicator');
        if(commonFun.isNull(dom)){
            return;
        }
        let indicators = dom.childNodes[0].childNodes;
        indicators[preIndex].innerHTML = "";
        indicators[index].style.backgroundColor='rgba(255,255,255, 0.4)';
        indicators[index].innerHTML = "<div id='active_indicator' style='height: 4px;background:#FFFFFF'></div>";
        let active = document.querySelector('#active_indicator');
        let nums = 0;
        let timer = setInterval(function () {
            nums++;
            active.style.width = (nums*1.67)+'%';
            if(nums>60){
                clearInterval(timer);
            }
        },50);//走60次
        // console.log(indicators,'index='+index,'preIndex='+preIndex);
        document.querySelector('.'+styles.bannerContentTextM).innerHTML = commonFun.isNull(this.state.advertData) ? '' : this.state.advertData[index]['content'];//重置手机端面的广告内容
    }

    loopProjects(){
        let dom = [];
        for (let key in this.state.featureData){
            // console.log('featureData key = '+key)
            dom.push(this.createFeatureList(this.state.featureData[key]));
        }
        return dom;
    }

    /**
     * 创建分类列表
     * @param data 子节点(features)
     * @returns {JSX.Element}
     */
    createFeatureList(data){
        return <div className={styles.featuredContainer} key={`feature_${data['id']}_${styles.featuredContainer}`}>
            <div className={styles.featuredBigTitleContainer} key={`feature_${data['id']}_${styles.featuredBigTitleContainer}`}>
                <div className={styles.featuredBigTitle} key={`feature_${data['id']}_${styles.featuredBigTitle}`}>{data['projectTitle']}</div>
                <Link to={`/club/projects/features/${data['id']}/${data['projectTitle']}`} className={styles.featuredBigMore} key={`feature_${data['id']}_${styles.featuredBigMore}`}>View More <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.1438 3.55444C10.7866 4.61305 11.9418 5.98415 13.9876 7.12688L15.5507 7.99992L13.9876 8.87296C11.9418 10.0157 10.7866 11.3868 10.1438 12.4454C9.82085 12.9772 9.62504 13.4341 9.51185 13.7485C9.45529 13.9057 9.41951 14.0268 9.39891 14.1034C9.38861 14.1416 9.38213 14.1686 9.37874 14.1834L9.37591 14.1961L9.37608 14.1953L9.37653 14.193L9.37684 14.1914L9.37702 14.1905C9.37712 14.19 9.37721 14.1895 8.39535 13.9999C7.41349 13.8103 7.41359 13.8098 7.4137 13.8093L7.41392 13.8081L7.41443 13.8055L7.41565 13.7994L7.41897 13.7833C7.4216 13.7709 7.42505 13.7551 7.42943 13.736C7.43819 13.6978 7.45066 13.6466 7.46764 13.5835C7.50159 13.4574 7.55362 13.2835 7.63006 13.0712C7.78287 12.6466 8.03389 12.0667 8.43424 11.4074C8.88488 10.6652 9.52203 9.82711 10.4139 8.99992H1V6.99992H10.4139C9.52203 6.17274 8.88488 5.33464 8.43424 4.59246C8.03389 3.93312 7.78287 3.35321 7.63006 2.92869C7.55362 2.71634 7.50159 2.54243 7.46764 2.41631C7.45066 2.35323 7.43819 2.30203 7.42943 2.26386C7.42505 2.24478 7.4216 2.22895 7.41897 2.21651L7.41565 2.20042L7.41443 2.19431L7.41392 2.19175L7.4137 2.19059C7.41359 2.19005 7.41349 2.18952 8.39535 1.99992C9.37721 1.81033 9.37712 1.80982 9.37702 1.80933L9.37684 1.80842L9.37653 1.80682L9.37608 1.80456C9.37593 1.80383 9.37586 1.80347 9.37586 1.80347L9.37591 1.80373L9.37874 1.81646C9.38213 1.83121 9.38861 1.85824 9.39891 1.8965C9.41951 1.97304 9.45529 2.09417 9.51185 2.2513C9.62504 2.56575 9.82085 3.0226 10.1438 3.55444Z" fill="black"/>
                </svg></Link>
            </div>
            <div className={styles.moreFeatured} key={`feature_${data['id']}_${styles.moreFeatured}`}>
                {
                    data['features'].map((item,key)=> {
                        return <div key={`feature_${item['id']}_${key}`} className={styles.featuredBox}>
                            <div className={styles.featuredBoxImg} key={`feature_${item['id']}_${key}_${styles.featuredBoxImg}`}>
                                <div className={styles.coverShade}></div>
                                <a href={item['href']} target='_blank' rel='noreferrer'>
                                    <img src={this.state.curWindowWidth>768 ? item['poster'] : (item['posterm'] || item['poster'])} key={`feature_${item['id']}_${key}_imgUrl`} alt='' />
                                </a>
                                <div className={styles.featuredBoxInfoContainer} key={`feature_${item['id']}_${key}_${styles.featuredBoxInfoContainer}`}>
                                    <Link className={styles.featuredNickRow} key={`feature_${item['id']}_${key}_${styles.featuredNickRow}`} to={`/club/creators/blogs/${item['creatorId']}`}>
                                        <img className={styles.featuredNick} src={item['sourceIcon']} key={`feature_${item['id']}_${key}_${styles.featuredNick}`} alt='' />
                                        <div className={styles.featuredNickname} key={`feature_${item['id']}_${key}_${styles.featuredNickname}`}>{item['author']}</div>
                                    </Link>
                                    <div className={styles.featuredTitleContainer} key={`feature_${item['id']}_${key}_${styles.featuredTitleContainer}`}>
                                        <div key={`feature_${item['id']}_${key}_${styles.featuredTitleContainer}_div`}>
                                            <div className={styles.featuredProductName} key={`feature_${item['id']}_${key}_${styles.featuredProductName}`}>{item['productName']}</div>
                                        </div>
                                        <div className={styles.featuredTitle} key={`feature_${item['id']}_${key}_${styles.featuredTitle}`}>{item['title']}</div>
                                    </div>
                                </div>
                            </div>
                        </div>;
                    })
                }
            </div>
            <Link to={`/club/projects/features/${data['id']}/${data['projectTitle']}`} className={styles.featuredBigMore2} key={`feature_${data['id']}_${styles.featuredBigMore}_2`}>View More <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.1438 3.55444C10.7866 4.61305 11.9418 5.98415 13.9876 7.12688L15.5507 7.99992L13.9876 8.87296C11.9418 10.0157 10.7866 11.3868 10.1438 12.4454C9.82085 12.9772 9.62504 13.4341 9.51185 13.7485C9.45529 13.9057 9.41951 14.0268 9.39891 14.1034C9.38861 14.1416 9.38213 14.1686 9.37874 14.1834L9.37591 14.1961L9.37608 14.1953L9.37653 14.193L9.37684 14.1914L9.37702 14.1905C9.37712 14.19 9.37721 14.1895 8.39535 13.9999C7.41349 13.8103 7.41359 13.8098 7.4137 13.8093L7.41392 13.8081L7.41443 13.8055L7.41565 13.7994L7.41897 13.7833C7.4216 13.7709 7.42505 13.7551 7.42943 13.736C7.43819 13.6978 7.45066 13.6466 7.46764 13.5835C7.50159 13.4574 7.55362 13.2835 7.63006 13.0712C7.78287 12.6466 8.03389 12.0667 8.43424 11.4074C8.88488 10.6652 9.52203 9.82711 10.4139 8.99992H1V6.99992H10.4139C9.52203 6.17274 8.88488 5.33464 8.43424 4.59246C8.03389 3.93312 7.78287 3.35321 7.63006 2.92869C7.55362 2.71634 7.50159 2.54243 7.46764 2.41631C7.45066 2.35323 7.43819 2.30203 7.42943 2.26386C7.42505 2.24478 7.4216 2.22895 7.41897 2.21651L7.41565 2.20042L7.41443 2.19431L7.41392 2.19175L7.4137 2.19059C7.41359 2.19005 7.41349 2.18952 8.39535 1.99992C9.37721 1.81033 9.37712 1.80982 9.37702 1.80933L9.37684 1.80842L9.37653 1.80682L9.37608 1.80456C9.37593 1.80383 9.37586 1.80347 9.37586 1.80347L9.37591 1.80373L9.37874 1.81646C9.38213 1.83121 9.38861 1.85824 9.39891 1.8965C9.41951 1.97304 9.45529 2.09417 9.51185 2.2513C9.62504 2.56575 9.82085 3.0226 10.1438 3.55444Z" fill="black"/>
            </svg></Link>
        </div>;
    }

    render() {
        return <div className={styles.pageContainer}>
                {
                    commonFun.isNull(this.state.advertData)
                        ? null
                        : <div className={styles.wheelBannerContainer}>
                            <div className={styles.coverShade} id="videoCoverShade" style={{height:'var(--video-height)'}}></div>
                                <video className={styles.bannerVideo} autoPlay loop playsInline muted
                                       poster={commonFun.isNull(this.state.advertData['href']) ? 'https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/banner/dev/Banner-2.png' : this.state.advertData['href']}
                                       src={commonFun.isNull(this.state.advertData['src']) ? 'https://bucket.zhiyun-tech.com/detail/club-video-home.mp4' : this.state.advertData['src']}></video>
                                <div className={styles.bannerContentContainer}>
                                    <Space vertical align='start' spacing='medium' className={styles.bannerContent}>
                                        <Space vertical align='start'>
                                            <Typography.Paragraph className={styles.bannerBigTitle}>{this.state.advertData['title']}</Typography.Paragraph>
                                            <Typography.Paragraph className={styles.bannerContentText}>{this.state.advertData['content']}</Typography.Paragraph>
                                        </Space>
                                    </Space>
                                </div>
                            <div className={styles.bannerContentTextM}>
                                <div className={styles.bannerContentTextMTitle}>{this.state.advertData['title']}</div>
                                <div>{this.state.advertData['content']}</div>
                            </div>
                        </div>
                }


                {/*Featured Works*/}
                {
                    commonFun.isNull(this.state.featureData)
                        ? null
                        : this.loopProjects()
                }

                {
                    this.hasPage
                        ? <div className={styles.loadMore} onClick={()=>{this.loadMore()}}>Load More</div>
                        : null
                }
        </div>
    }
}