import React, {Component} from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonFun from "../../../functions/commonFun";
import styles from '../../css/creatorsClub/PageRule.module.css'
import apiService from "../../../functions/apiService";
gsap.registerPlugin(ScrollToPlugin);

/**
 * 条款协议
 */
export default class PageRule extends Component {
    canTrackingEnd=false;//能否发送页面结束追踪
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        commonFun.setGlobalData('MainNavSelected',4);
        gsap.to(window, {duration: commonFun.pageDuration, scrollTo:0});//回到0高度

        setTimeout(() => {//追踪代码
            if(!this.canTrackingEnd){
                apiService.viewerTracking({page:'rule',type:'club',action:'start'});
                this.canTrackingEnd=true
                apiService.clickTracking();//A标签点击追踪
            }
        },commonFun.validSeconds);

        commonFun.setSeoInfos("PageRule","club","About&Rules","","")
    }

    componentWillUnmount() {
        if(this.canTrackingEnd){
            apiService.viewerTracking({page:'rule',type:'club',action:'end'});
            this.canTrackingEnd=false;
        }
    }

    render() {
        return <div className={styles.pageContainer}>
            {/*大标题*/}
            <div className={styles.topContainer}>
                <div className={styles.topText}>About&Rules</div>
            </div>

            {/*卡片1内容*/}
            <div className={styles.cardContainer}>
                <div className={styles.cardTextContainer}>
                    <div className={styles.cardLeft}>Level badges introduce</div>
                    <div className={styles.cardRight}>
                        <div className={styles.cardRight1Container}>
                            <div className={styles.cardRight1Top}>ZHIIYUN will select the best creators and award level badges after creator recruitment, competitions and other activities.</div>
                            <div className={styles.cardRight1Bottom}>
                                <div className={styles.cardRight1BottomContainer}>
                                    <div className={styles.cardRight1BottomLeftRound}>
                                        <div style={{paddingLeft:24,paddingTop:24}}>
                                            <img src="https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-icon-badge-gold.png" alt='' style={{width:80,height:80}}/>
                                            <div className={styles.cardRight1BottomTitle}>Gold creator</div>
                                        </div>
                                        <img src="https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-icon-badge-gold-bg.svg" alt=''/>
                                    </div>
                                    <div className={styles.cardRight1BottomContent}>The high-quality creators selected by ZHIYUN and the winners of the competition will be awarded this badge.</div>
                                </div>
                                <div className={styles.cardRight1BottomContainer}>
                                    <div className={styles.cardRight1BottomRightRound}>
                                        <div style={{paddingLeft:24,paddingTop:24}}>
                                            <img src="https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-icon-badge-star.png" alt='' style={{width:80,height:80}}/>
                                            <div className={styles.cardRight1BottomTitle}>Star creator</div>
                                        </div>
                                        <img src="https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-icon-badge-star-bg.svg" alt=''/>
                                    </div>
                                    <div className={styles.cardRight1BottomContent}>If you cooperate with ZHIYUN or other manufacturers through ZHIYUN CreatorsClub 3 times in the future, you will get the STAR Creator logo and get 2000USD bonus. Screenshots of conversations and proof of links to works are required.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*卡片2内容*/}
            <div className={`${styles.cardContainer} ${styles.cardContainerBgGrey}`}>
                <div className={styles.cardTextContainer}>
                    <div className={styles.cardLeft}>How to become a ZHIYUN Creator</div>
                    <div className={styles.cardRight}>
                        <div className={styles.cardRight2Container}>
                            {/*<div className={styles.cardRight2Row}>*/}
                            {/*    <div className={styles.dotContainer}>*/}
                            {/*        <div className={styles.dotOuter}><div className={styles.dot1}></div></div>*/}
                            {/*        <div className={styles.vLine1}></div>*/}
                            {/*    </div>*/}
                            {/*    <div className={styles.cardRight2TimelineContainer}>*/}
                            {/*        <div className={styles.cardRight2TimelineTitle}>STEP 1: Apply</div>*/}
                            {/*        <div className={styles.cardRight2TimelineContent}>*/}
                            {/*            Select a product/project that you’re interested in. Click the “Apply” button,*/}
                            {/*            then fill out the application, and wait for us to get back to you.*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className={styles.cardRight2Row}>
                                <div className={styles.dotContainer}>
                                    <div className={styles.dotOuter}><div className={styles.dot1}></div></div>
                                    <div className={styles.cardRight2TimelineTitle}>STEP 1: Apply</div>
                                </div>
                                <div className={styles.cardRight2TimelineContainer}>
                                    <div className={styles.vLine1}></div>
                                    <div className={styles.cardRight2TimelineContent}>
                                        Select a product/project that you’re interested in. Click the “Apply” button,
                                        then fill out the application, and wait for us to get back to you.
                                    </div>
                                </div>
                            </div>

                            <div className={styles.cardRight2Row}>
                                <div className={styles.dotContainer}>
                                    <div className={styles.dotOuter}><div className={styles.dot2}></div></div>
                                    <div className={styles.cardRight2TimelineTitle}>STEP 2: Wait for the Announcement</div>
                                </div>
                                <div className={styles.cardRight2TimelineContainer}>
                                    <div className={styles.vLine1}></div>
                                    <div className={styles.cardRight2TimelineContent}>If you are selected, we will notify you by email.</div>
                                </div>
                            </div>

                            <div className={styles.cardRight2Row}>
                                <div className={styles.dotContainer}>
                                    <div className={styles.dotOuter}><div className={styles.dot3}></div></div>
                                    <div className={styles.cardRight2TimelineTitle}>STEP 3: Receive the Product or Get a Paid Job</div>
                                </div>
                                <div className={styles.cardRight2TimelineContainer}>
                                    <div className={styles.vLine1}></div>
                                    <div className={styles.cardRight2TimelineContent}>
                                        Receive the product for free or get a paid project offer.
                                        We will cover the shipment & delivery fees and ship the product directly from the factory to your address.
                                        Delivery normally takes 1-2 weeks.
                                    </div>
                                </div>
                            </div>

                            <div className={styles.cardRight2Row}>
                                <div className={styles.dotContainer}>
                                    <div className={styles.dotOuter}><div className={styles.dot4}></div></div>
                                    <div className={styles.cardRight2TimelineTitle}>STEP 4: Submit Your Video</div>
                                </div>
                                <div className={styles.cardRight2TimelineContainer}>
                                    <div className={styles.vLine1}></div>
                                    <div className={styles.cardRight2TimelineContent}>
                                        Film a video in accordance with the rules,
                                        upload the video to your own social media account with the hashtag <b>#ZHIYUNCreatorsClub</b>,
                                        and submit the link of the post to <b>CreatorsClub@zhiyun-tech.com</b>.
                                    </div>
                                </div>
                            </div>

                            <div className={styles.cardRight2Row}>
                                <div className={styles.dotContainer}>
                                    <div className={styles.dotOuter}><div className={styles.dot5}></div></div>
                                    <div className={styles.cardRight2TimelineTitle}>STEP 5: Display Your Work/Get Paid</div>
                                </div>
                                <div className={styles.cardRight2TimelineContainer}>
                                    <div className={styles.vLine1}></div>
                                    <div className={styles.cardRight2TimelineContent}>
                                        High-quality content will have the chance of being pinned to the homepage,
                                        and/or being reposted on ZHIYUN social media pages to get more views.
                                        Good creators may also get more opportunities to get paid projects.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/*卡片3内容*/}
            <div className={`${styles.cardContainer}`}>
                <div className={styles.cardTextContainer}>
                    <div className={styles.cardLeft}>Content Requirement & Submission Method</div>
                    <div className={styles.cardRight}>
                        <div className={styles.cardRight3Container}>
                            Choose a type of video related to the product you’d like to make. It can be B-roll+BTS, Gear Review, Real World Testing Review,
                            Filmmaking Tips/Tutorials, Short Film/Mini-Documentary, Product Commercial, etc. Please refer to the specific requirements of each project for details.
                            You might get paid for your work for some projects.
                            <br/><br/>
                            Please submit your work within 2 weeks upon receiving the product.
                            Please share your video content on social media  (YouTube, Instagram, Facebook, Twitter, TikTok, Vimeo, etc.),
                            and set to public with the hashtag <b>#ZHIYUNCreatorsClub</b> added to your post. Then submit the post’s link to <b>CreatorsClub@zhiyun-tech.com</b>.
                            <br/><br/>
                            Those who fail to submit their work within the agreed time, or whose work is obviously perfunctory,
                            will be disqualified for future Creators Club projects.
                            (Please send CreatorsClub@zhiyun-tech.com a message in advance if you cannot meet the deadline due to extenuating circumstances)
                        </div>
                    </div>
                </div>
            </div>

            {/*卡片4内容*/}
            <div className={`${styles.cardContainer} ${styles.cardContainerBgGrey}`}>
                <div className={styles.cardTextContainer}>
                    <div className={styles.cardLeft}>Opportunities for Displaying Your Work</div>
                    <div className={styles.cardRight}>
                        <div className={styles.cardRight4Container}>
                            High-quality content will be pinned to the homepage, and/or reposted on ZHIYUN social media pages to get more views.
                            <br/><br/>
                            All content exposure through various ZHIYUN channels will be labeled with the creator’s ID.
                            <br/><br/>
                            Once you have submitted your work, ZHIYUN reserves the right to use, edit, and repost your submitted content.
                        </div>
                    </div>
                </div>
            </div>

            {/*卡片5内容*/}
            <div className={`${styles.cardContainer}`}>
                <div className={styles.cardTextContainer}>
                    <div className={styles.cardLeft}>Perks of being a ZHIYUN Creator</div>
                    <div className={styles.cardRight}>
                        <div className={styles.cardRight5Container}>
                            <div className={styles.cardRight5Row}>
                                <div className={styles.cardRight5RowSvg}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 17L18.073 9.929L16.659 8.515L11.003 14.172L8.174 11.343L6.76 12.757L11.003 17Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className={styles.cardRight5RowContent}>Receive the product you applied for free.</div>
                            </div>

                            <div className={styles.cardRight5Row}>
                                <div className={styles.cardRight5RowSvg}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 17L18.073 9.929L16.659 8.515L11.003 14.172L8.174 11.343L6.76 12.757L11.003 17Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className={styles.cardRight5RowContent}>Marked as ZHIYUN creator in ZHIYUN talent pool, gain priority to be chosen for future official collaborations and paid projects.</div>
                            </div>

                            <div className={styles.cardRight5Row}>
                                <div className={styles.cardRight5RowSvg}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 17L18.073 9.929L16.659 8.515L11.003 14.172L8.174 11.343L6.76 12.757L11.003 17Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className={styles.cardRight5RowContent}>Increase the exposure of your work on official ZHIYUN social media platforms.</div>
                            </div>

                            <div className={styles.cardRight5Row}>
                                <div className={styles.cardRight5RowSvg}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 17L18.073 9.929L16.659 8.515L11.003 14.172L8.174 11.343L6.76 12.757L11.003 17Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className={styles.cardRight5RowContent}>You might get paid in certain Creators Club programs.</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    }
}