import React, {Component} from 'react';
// import { Typography, Space } from '@douyinfe/semi-ui';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonFun from "../../../functions/commonFun";
import apiService from "../../../functions/apiService";
import styles from '../../css/creatorsClub/PageProjectFeatures.module.css'
// import {Link} from "react-router-dom";
gsap.registerPlugin(ScrollToPlugin);
/**
 * Featured works 列表
 */
export default class PageProjectFeatures extends Component {
    httpRequest=false;//是否已经进行HTTP请求，防止重复请求
    page=1;//当前翻页
    hasPage=false;//能否进行翻页，根据返回的数据进行判断
    limit=20;//每页数量
    canTrackingEnd=false;//能否发送页面结束追踪
    constructor(props) {
        super(props);
        this.state = {
            projectId:commonFun.getUrlParams('','/',6),//project id
            projectTitle:decodeURIComponent(commonFun.getUrlParams('','/',7)),
            featureData:null,
            curWindowWidth:commonFun.getWidth()
        }
    }

    componentDidMount() {
        // console.log('project id = ' + this.state.projectId)
        if(!this.httpRequest){
            this.httpRequest = true;
            if(commonFun.isNull(commonFun.getGlobalData('ptoken'))){//无法获取ptoken
                let timerCount = 0;
                let pTimer = setInterval( ()=> {
                    timerCount++;
                    if (timerCount > 10) {//防止死循环
                        clearInterval(pTimer)
                    }
                    if(!commonFun.isNull(commonFun.getGlobalData('ptoken'))) {
                        clearInterval(pTimer)
                        apiService.getFeatures({page:this.page,limit:this.limit,project_id:this.state.projectId},this.getFeatureData.bind(this));
                    }
                },1000);
            }else{
                apiService.getFeatures({page:this.page,limit:this.limit,project_id:this.state.projectId},this.getFeatureData.bind(this));
            }

        }
        commonFun.setGlobalData('MainNavSelected',2);
        window.addEventListener('resize', this.handleResize.bind(this)); //监听窗口大小改变
        gsap.to(window, {duration: commonFun.pageDuration, scrollTo:0});//回到0高度

        setTimeout(() => {//追踪代码
            if(!this.canTrackingEnd){
                apiService.viewerTracking({page:'project_features',type:'club',action:'start'});
                this.canTrackingEnd=true;
                apiService.clickTracking();//A标签点击追踪
            }
        },commonFun.validSeconds);
    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.handleResize.bind(this));
        if(this.canTrackingEnd){
            apiService.viewerTracking({page:'project_features',type:'club',action:'end'});
            this.canTrackingEnd=false;
        }
    }

    /**
     * 更新容器大小
     */
    handleResize(){
        this.setState({curWindowWidth:commonFun.getWidth()})
    }

    /**
     * 获取Features数据
     * @param result
     */
    getFeatureData(result){
        if(!commonFun.isNull(result)){
            if(result.length===this.limit){
                this.hasPage = true;
            }else{
                this.hasPage = false;
            }
            if(this.page>1){//翻页数据，对数据进行合并
                let tmp = this.state.featureData;
                for(let i=0;i<result.length;i++){
                    tmp.push(result[i]);
                }
                result = tmp;
                tmp = null;
            }
            this.setState({featureData:result});
            commonFun.setSeoInfos("PageProjectFeatures","club",this.state.projectTitle + " features","","")
        }
    }

    /**
     * 加载数据
     */
    loadMore(){
        this.page += 1;
        apiService.getFeatures({page:this.page,limit:this.limit,project_id:this.state.projectId},this.getFeatureData.bind(this));
    }





    render() {
        return commonFun.isNull(this.state.featureData) ? null : <div className={styles.pageContainer}>
                {
                    commonFun.isNull(this.state.featureData) ? null : <div className={styles.pageContainerTopBackground}>
                    </div>
                }

                {/*Featured Works*/}
                {
                    commonFun.isNull(this.state.featureData)
                        ? null
                        : <div className={styles.featuredContainer}>
                            <div className={styles.featuredBigTitleContainer}>
                                <div className={styles.featuredBigTitle}>{this.state.projectTitle}</div>
                                <div className={styles.featuredBigMore}>All Works</div>
                            </div>
                            <div className={styles.moreFeatured} style={this.state.featureData.length%3===0 ? null :{justifyContent:'flex-start'}}>
                                {
                                    this.state.featureData.map((item,key)=> {
                                        return <a key={`feature_${item['id']}_${key}`} className={styles.featuredBox}  href={item['href']} style={this.state.featureData.length%3===0 ? null :{marginRight:'16px'}}>
                                            <div className={styles.featuredBoxImg} key={`feature_${item['id']}_${key}_${styles.featuredBoxImg}`}>
                                                <div className={styles.coverShade}></div>
                                                <img src={this.state.curWindowWidth>768 ? item['poster'] : (item['posterm'] || item['poster'])} key={`feature_${item['id']}_${key}_imgUrl`} alt='' />
                                                <div className={styles.featuredBoxInfoContainer} key={`feature_${item['id']}_${key}_${styles.featuredBoxInfoContainer}`}>
                                                    <div className={styles.featuredNickRow} key={`feature_${item['id']}_${key}_${styles.featuredNickRow}`}>
                                                        <img className={styles.featuredNick} src={item['sourceIcon']} key={`feature_${item['id']}_${key}_${styles.featuredNick}`}  alt='' />
                                                        <div className={styles.featuredNickname} key={`feature_${item['id']}_${key}_${styles.featuredNickname}`}>{item['author']}</div>
                                                    </div>
                                                    <div className={styles.featuredTitleContainer} key={`feature_${item['id']}_${key}_${styles.featuredTitleContainer}`}>
                                                        <div key={`feature_${item['id']}_${key}_${styles.featuredTitleContainer}_div`}>
                                                            <div className={styles.featuredProductName} key={`feature_${item['id']}_${key}_${styles.featuredProductName}`}>{item['productName']}</div>
                                                        </div>
                                                        <div className={styles.featuredTitle} key={`feature_${item['id']}_${key}_${styles.featuredTitle}`}>{item['title']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>;
                                    })
                                }
                            </div>
                        </div>
                }

                {
                    this.hasPage
                        ? <div className={styles.loadMore} onClick={()=>{this.loadMore()}}>Load More</div>
                        : null
                }
        </div>
    }
}