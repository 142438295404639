import React, {Component} from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonFun from "../../../functions/commonFun";
import apiService from "../../../functions/apiService";
import styles from '../../css/creatorsClub/PageEventsDetail.module.css'
import {Link} from "react-router-dom";
gsap.registerPlugin(ScrollToPlugin);

/**
 * Events Detail 项目详情
 */
export default class PageEventsDetail extends Component {
    httpRequest=false;//是否已经进行HTTP请求，防止重复请求
    canTrackingEnd=false;//能否发送页面结束追踪
    constructor(props) {
        super(props);
        this.state = {
            id:commonFun.getUrlParams('','/',6),//event id
            eventData:null,
            blogs:null,
            curWindowWidth:commonFun.getWidth()
        }
    }

    componentDidMount() {
        if(!this.httpRequest){
            this.httpRequest = true;
            if(commonFun.isNull(commonFun.getGlobalData('ptoken'))){//无法获取ptoken
                let timerCount = 0;
                let pTimer = setInterval( ()=> {
                    timerCount++;
                    if (timerCount > 10) {//防止死循环
                        clearInterval(pTimer)
                    }
                    if(!commonFun.isNull(commonFun.getGlobalData('ptoken'))) {
                        clearInterval(pTimer)
                        apiService.getEventsDetail(this.state.id,this.getEventData.bind(this));
                    }
                },1000);
            }else{
                apiService.getEventsDetail(this.state.id,this.getEventData.bind(this));
            }
        }
        window.addEventListener('resize', this.handleResize.bind(this)); //监听窗口大小改变
        gsap.to(window, {duration: commonFun.pageDuration, scrollTo:0});//回到0高度

        setTimeout(() => {//追踪代码
            if(!this.canTrackingEnd){
                apiService.viewerTracking({page:'events_detail',type:'club',action:'start'});
                this.canTrackingEnd = true;
                apiService.clickTracking();//A标签点击追踪
            }
        },commonFun.validSeconds);
    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.handleResize.bind(this));
        if(this.canTrackingEnd){
            apiService.viewerTracking({page:'events_detail',type:'club',action:'end'});
            this.canTrackingEnd=false;
        }
    }

    /**
     * 更新容器大小
     */
    handleResize(){
        this.setState({curWindowWidth:commonFun.getWidth()})
    }

    /**
     * 获取Event数据
     * @param result
     */
    getEventData(result){
        if(!commonFun.isNull(result)){
            if(result['code']===0){
                this.setState({eventData:result['data'],blogs:result['blogs']})
                commonFun.setSeoInfos("PageEventsDetail","club",result['data']['projectName'],"","")
            }
        }
    }

    /**
     * 用户回片
     * @param data 子节点(features)
     * @returns {JSX.Element}
     */
    createFeatureList2(){
        return this.state.blogs.length<1 ? '' : <div className={styles.featuredContainer} key={`feature_${this.state.eventData['id']}_${styles.featuredContainer}`}>
            <div className={styles.featuredBigTitleContainer} key={`feature_${this.state.eventData['id']}_${styles.featuredBigTitleContainer}`}>
                <div className={styles.featuredBigTitle} key={`feature_${this.state.eventData['id']}_${styles.featuredBigTitle}`}>Featured works</div>
            </div>
            <div className={styles.moreFeatured} key={`feature_${this.state.eventData['id']}_${styles.moreFeatured}`} style={this.state.blogs.length%4===0 ? null :{justifyContent:'flex-start'}}>
                {
                    this.state.blogs.map((item,key)=> {
                        return <div key={`feature_${item['id']}_${key}`} className={styles.featuredBox} style={this.state.blogs.length%4===0 ? null :{marginRight:'8px'}}>
                            <div className={styles.featuredBoxImg} key={`feature_${item['id']}_${key}_${styles.featuredBoxImg}`}>
                                <a href={item['href']} target='_blank' rel='noreferrer'>
                                    <img src={this.state.curWindowWidth>768 ? item['poster'] : (item['posterm'] || item['poster'])} key={`feature_${item['id']}_${key}_imgUrl`} alt='' />
                                </a>
                                <div className={styles.featuredBoxInfoContainer} key={`feature_${item['id']}_${key}_${styles.featuredBoxInfoContainer}`}>
                                    <Link className={styles.featuredNickRow} key={`feature_${item['id']}_${key}_${styles.featuredNickRow}`} to={`/club/creators/blogs/${item['creatorId']}`}>
                                        <img className={styles.featuredNick} src={item['sourceIcon']} key={`feature_${item['id']}_${key}_${styles.featuredNick}`} alt='' />
                                        <div className={styles.featuredNickname} key={`feature_${item['id']}_${key}_${styles.featuredNickname}`}>{item['author']}</div>
                                    </Link>
                                    <div className={styles.featuredTitleContainer} key={`feature_${item['id']}_${key}_${styles.featuredTitleContainer}`}>
                                        <div key={`feature_${item['id']}_${key}_${styles.featuredTitleContainer}_div`}>
                                            <div className={styles.featuredProductName} key={`feature_${item['id']}_${key}_${styles.featuredProductName}`}>{item['productName']}</div>
                                        </div>
                                        <div className={styles.featuredTitle} key={`feature_${item['id']}_${key}_${styles.featuredTitle}`}>{item['title']}</div>
                                    </div>
                                </div>
                            </div>
                        </div>;
                    })
                }
            </div>
            <Link to={`/club/projects/features/${this.state.eventData['id']}/${this.state.eventData['projectTitle']}`} className={styles.featuredBigMore2} key={`feature_${this.state.eventData['id']}_${styles.featuredBigMore}_2`}>View More →</Link>
        </div>;
    }

    /**
     * 用户回片
     * @param data 子节点(features)
     * @returns {JSX.Element}
     */
    createFeatureList(){
        return (commonFun.isNull(this.state.blogs) || this.state.blogs.length<1)
            ? null
            : <div className={styles.featuredContainer}>
                <div className={styles.featuredBigTitleContainer}>
                    <div className={styles.featuredBigTitle}>All Works</div>
                    <Link to={`/club/projects/features/${this.state.eventData['id']}/${this.state.eventData['projectTitle']}`} className={styles.featuredBigMore}>View More →</Link>
                </div>

                <div className={styles.moreFeatured}>
                    {
                        this.state.blogs.map((item,key)=> {
                            return <div key={`feature_${key}`} className={styles.featuredBox}>
                                <div className={styles.featuredBoxImg}>
                                    <div className={styles.coverShade}></div>
                                    <a href={item['href']} target='_blank' rel='noreferrer'><img src={this.state.curWindowWidth>768 ? item['poster'] : (item['posterm'] || item['poster'])}  alt='' /> </a>
                                    <div className={styles.featuredBoxInfoContainer}>
                                        <Link className={styles.featuredNickRow} to={`/club/creators/blogs/${item['creatorId']}`}>
                                            <img className={styles.featuredNick} src={item['sourceIcon']}  alt=''/>
                                            <div className={styles.featuredNickname}>{item['author']}</div>
                                        </Link>
                                        <div className={styles.featuredTitleContainer}>
                                            <div>
                                                <div className={styles.featuredProductName}>{item['productName']}</div>
                                            </div>
                                            <div className={styles.featuredTitle}>{item['title']}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        })
                    }

                </div>
            </div>;
    }

    /**
     * 打开申请表单
     */
    openApplyForm(){
        document.querySelector('.'+styles.applyFormOutContainer).classList.add(styles.applyFormShow);
        document.querySelector('.'+styles.applyFormContainer).classList.add(styles.applyFormShow);
    }

    /**
     * 关闭申请表单
     */
    closeApplyForm(){
        document.querySelector('.'+styles.applyFormOutContainer).classList.remove(styles.applyFormShow);
        document.querySelector('.'+styles.applyFormContainer).classList.remove(styles.applyFormShow);
    }

    /**
     * 申请表单提交
     */
    submitApplyForm(){
        let params = {
            "project":this.state.id,
            "name":document.querySelector("[name=name]").value,
            "country":document.querySelector("[name=country]").value,
            "email":document.querySelector("[name=email]").value,
            "device":document.querySelector("[name=device]").value,
            "fulltime":document.querySelector("[name=fulltime]").checked ? 1 : 0,
            "href":document.querySelector("[name=href]").value,
            "links":document.querySelector("[name=link]").value,
            "awards":document.querySelector("[name=awards]").value
        };
        document.querySelector('.'+styles.applyFormTipsRow).classList.add(styles.applyFormTipsError);
        if(commonFun.isNull(params['name'])){
            document.querySelector('.'+styles.applyFormTipsRow).innerHTML = "Name/Studio can not be none!";
            document.querySelector("[name=name]").focus();
            return false;
        }else if(commonFun.isNull(params['country'])){
            document.querySelector('.'+styles.applyFormTipsRow).innerHTML = "country can not be none!";
            document.querySelector("[name=country]").focus();
            return false;
        }else if(commonFun.isNull(params['email'])){
            document.querySelector('.'+styles.applyFormTipsRow).innerHTML = "email can not be none!";
            document.querySelector("[name=email]").focus();
            return false;
        }else if(commonFun.isNull(params['device'])){
            document.querySelector('.'+styles.applyFormTipsRow).innerHTML = "device can not be none!";
            document.querySelector("[name=device]").focus();
            return false;
        }else if(commonFun.isNull(params['href'])){
            document.querySelector('.'+styles.applyFormTipsRow).innerHTML = "Medias can not be none!";
            document.querySelector("[name=href]").focus();
            return false;
        }else if(commonFun.isNull(params['links'])){
            document.querySelector('.'+styles.applyFormTipsRow).innerHTML = "Works can not be none!";
            document.querySelector("[name=link]").focus();
            return false;
        }

        //防止重复提交
        document.querySelector('.'+styles.applyFormSubmitBtn).classList.add(styles.applyFormSubmitBtnDisable);
        document.querySelector('.'+styles.applyFormTipsRow).classList.remove(styles.applyFormTipsSuccess);
        document.querySelector('.'+styles.applyFormTipsRow).classList.remove(styles.applyFormTipsError);

        apiService.submitApplyForm(params,(result)=>{
            if(result.code===0){
                document.querySelector('.'+styles.applyFormTipsRow).classList.add(styles.applyFormTipsSuccess);
                document.querySelector('.'+styles.applyFormTipsRow).innerHTML = result.message
            }else{
                document.querySelector('.'+styles.applyFormSubmitBtn).classList.remove(styles.applyFormSubmitBtnDisable);
                document.querySelector('.'+styles.applyFormTipsRow).classList.add(styles.applyFormTipsError);
                document.querySelector('.'+styles.applyFormTipsRow).innerHTML = result.message
            }
        });
    }

    render() {
        return !this.httpRequest ? null : <div className={styles.pageContainer}>
            {
                //banner ad 小广告
                commonFun.isNull(this.state.eventData) ? null : <div className={styles.adBannerContainer}>
                    <div className={styles.adBanner}>{this.state.eventData['projectName']}</div>
                </div>
            }

            {
                commonFun.isNull(this.state.eventData)
                    ? null
                    : <>
                        <div className={styles.eventContainer}>
                            <div className={styles.firstEvent}>
                                <div className={styles.firstEventImg}>
                                    <img src={this.state.curWindowWidth>768 ? this.state.eventData['poster'] : this.state.eventData['posterm']} alt='' />
                                    {
                                        this.state.eventData['isFinish']
                                            ? <div className={`${styles.eventStatusBox} ${styles.eventStatusClose}`}>Closed</div>
                                            : <div className={`${styles.eventStatusBox} ${styles.eventStatusActive}`}>On-going</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={styles.infoContainer}>
                            <div className={styles.infoText}>
                                <div className={styles.infoTextTitle}>{this.state.eventData['projectTitle']}</div>
                                <div className={styles.infoTextApply}>
                                    <div className={styles.infoTextApplyRow}>
                                        <div className={styles.text}>Required</div>
                                        <div className={styles.nums}>{this.state.eventData['required']}</div>
                                    </div>

                                    <div className={styles.infoTextApplyRow}>
                                        <div className={styles.vLine}></div>
                                    </div>

                                    <div className={styles.infoTextApplyRow}>
                                        <div className={styles.text}>Applied</div>
                                        <div className={styles.nums}>{this.state.eventData['applied']}</div>
                                    </div>

                                    <div className={styles.infoTextApplyRow}>
                                        <div className={styles.vLine}></div>
                                    </div>

                                    <div className={styles.infoTextApplyRow}>
                                        <div className={styles.text}>Price</div>
                                        <div className={styles.nums}>${this.state.eventData['price']}</div>
                                    </div>

                                </div>
                            </div>
                            <div className={styles.infoTimeline}>
                                <div className={`${styles.rowLine} ${styles.rowLineBigTitle}`}>Timeline</div>
                                <div className={styles.rowLine}>
                                    <div className={styles.dotOrange}></div>
                                    <div className={styles.rowLineTitle}>Application Opens</div>
                                </div>
                                <div className={styles.rowLine2}>{commonFun.formatDate(this.state.eventData['startAt'],'Y-M-D',true)}</div>

                                <div className={styles.rowLine}>
                                    <div className={styles.dotBlue}></div>
                                    <div className={styles.rowLineTitle}>Application Closes</div>
                                </div>
                                <div className={styles.rowLine2}>{commonFun.formatDate(this.state.eventData['endAt'],'Y-M-D',true)}</div>

                                {
                                    commonFun.isNull(this.state.eventData['submissionAt'])
                                        ? null
                                        : <>
                                            <div className={styles.rowLine}>
                                                <div className={styles.dotGreen}></div>
                                                <div className={styles.rowLineTitle}>Product Delivery & Video Submission</div>
                                            </div>
                                            <div className={styles.rowLine2}>{this.state.eventData['submissionAt']}</div>
                                        </>
                                }

                                {
                                    commonFun.isNull(this.state.eventData['showcaseAt'])
                                        ? null
                                        : <>
                                            <div className={styles.rowLine}>
                                                <div className={styles.dotPurple}></div>
                                                <div className={styles.rowLineTitle}>Creators’ submitted work showcase</div>
                                            </div>
                                            <div className={styles.rowLine2}>{this.state.eventData['showcaseAt']}</div>
                                        </>
                                }

                            </div>
                        </div>

                        {/*<div className={styles.detailContainer} dangerouslySetInnerHTML={{__html:this.state.eventData['content']}}></div>*/}

                        {
                            this.state.eventData['content'].split("<hr />").map((v,k)=>{
                                if(k%2===0){
                                    return  <div key={`"detailContainer_${k}"`} className={styles.detailContainer} dangerouslySetInnerHTML={{__html:v}} style={{backgroundColor:"#F4F7F9"}}></div>
                                }else{
                                    return  <div key={`"detailContainer_${k}"`} className={styles.detailContainer} dangerouslySetInnerHTML={{__html:v}} style={{backgroundColor:"#FFFFFF"}}></div>
                                }
                            })
                        }

                        {this.createFeatureList()}

                        <div className={styles.btnApply} style={{display:this.state.eventData['isFinish'] ? "none" : ""}} onClick={()=>{this.openApplyForm()}}>APPLY NOW</div>

                        {/*申请表单*/}
                        <div className={styles.applyFormOutContainer} onClick={()=>{this.closeApplyForm()}}></div>
                        <div className={styles.applyFormContainer}>
                            <div className={styles.applyFormTitleContainer}>
                                <div className={styles.applyFormTitleText}>APPLY NOW</div>
                                <img className={styles.applyFormClose} src='https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/detail/explore/explore-icon-close.svg'
                                     onClick={()=>{this.closeApplyForm()}} alt=''/>
                            </div>
                            <div className={styles.applyFormRow}>
                                <div className={styles.applyFormColumn}>
                                    <div className={styles.applyFormColumnTitle}>Name/Studio</div>
                                    <input type="text" name="name" className={styles.applyFormColumnInput} />
                                </div>

                                <div className={styles.applyFormColumn}>
                                    <div className={styles.applyFormColumnTitle}>Country</div>
                                    <input type="text" name="country" className={styles.applyFormColumnInput} />
                                </div>
                            </div>

                            <div className={styles.applyFormRow}>
                                <div className={styles.applyFormColumn}>
                                    <div className={styles.applyFormColumnTitle}>Email</div>
                                    <input type="email" name="email" className={styles.applyFormColumnInput} />
                                </div>

                                <div className={styles.applyFormColumn}>
                                    <div className={styles.applyFormColumnTitle}>Your main equipment for shooting</div>
                                    <input type="text" name="device" className={styles.applyFormColumnInput} />
                                </div>
                            </div>

                            <div className={styles.applyFormRow}>
                                <div className={styles.applyFormColumn}>
                                    <div className={styles.applyFormColumnTitle}>Are you a full-time content creator?</div>
                                    <div  className={styles.applyFormColumnInput2}>
                                        <label><input type="radio" name="fulltime" value='1' />Yes</label>
                                        <label><input type="radio" name="fulltime" value='0' />No</label>
                                    </div>
                                </div>

                                <div className={styles.applyFormColumn}>
                                    <div className={styles.applyFormColumnTitle}>Social media accounts used to showcase works</div>
                                    <input type="url" name="href" className={styles.applyFormColumnInput} />
                                </div>
                            </div>

                            <div className={styles.applyFormRow}>
                                <div className={styles.applyFormColumn}>
                                    <div className={styles.applyFormColumnTitle}>Links to your work</div>
                                    <input type="url" name="link" className={styles.applyFormColumnInput} />
                                </div>

                                <div className={styles.applyFormColumn}>
                                    <div className={styles.applyFormColumnTitle}>Awards</div>
                                    <input type="text" name="awards" className={styles.applyFormColumnInput} />
                                </div>
                            </div>

                            <div className={`${styles.applyFormTipsRow}`}>
                                Error Error Error Error Error Error Error
                            </div>

                            <div className={styles.applyFormRow} style={{marginTop:16}}>
                                <div className={styles.applyFormSubmitBtn} onClick={this.submitApplyForm.bind(this)}>SUBMIT</div>
                            </div>

                        </div>

                    </>
            }
        </div>
    }
}