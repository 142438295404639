import React, {Component} from 'react';
// import {Link} from "react-router-dom";
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonFun from "../../../functions/commonFun";
import apiService from "../../../functions/apiService";
import styles from '../../css/creatorsClub/PageProjects.module.css'
gsap.registerPlugin(ScrollToPlugin);
/**
 * Projects 招募项目列表【招募 招募 招募 招募 招募 招募 招募 招募 招募 招募 招募 招募】
 */
export default class PageProjects extends Component {
    httpRequest=false;//是否已经进行HTTP请求，防止重复请求
    page=1;//当前翻页
    hasPage=false;//能否进行翻页，根据返回的数据进行判断
    limit=5;//每页数量
    canTrackingEnd=false;//能否发送页面结束追踪
    constructor(props) {
        super(props);
        this.state = {
            projectData:null,
            curWindowWidth:commonFun.getWidth()
        }
    }

    componentDidMount() {
        if(!this.httpRequest){
            this.httpRequest = true;
            if(commonFun.isNull(commonFun.getGlobalData('ptoken'))){//无法获取ptoken
                let timerCount = 0;
                let pTimer = setInterval( ()=> {
                    timerCount++;
                    if (timerCount > 10) {//防止死循环
                        clearInterval(pTimer)
                    }
                    if(!commonFun.isNull(commonFun.getGlobalData('ptoken'))) {
                        clearInterval(pTimer)
                        apiService.getProjects({page:this.page,limit:this.limit},this.getProjectData.bind(this));
                    }
                },1000);
            }else{
                apiService.getProjects({page:this.page,limit:this.limit},this.getProjectData.bind(this));
            }
        }
        commonFun.setGlobalData('MainNavSelected',1);
        window.addEventListener('resize', this.handleResize.bind(this)); //监听窗口大小改变
        gsap.to(window, {duration: commonFun.pageDuration, scrollTo:0});//回到0高度

        setTimeout(() => {//追踪代码
            if(!this.canTrackingEnd){
                apiService.viewerTracking({page:'projects',type:'club',action:'start'});
                this.canTrackingEnd=true;
                apiService.clickTracking();//A标签点击追踪
            }
        },commonFun.validSeconds);
        commonFun.setSeoInfos("PageProjects","club","Projects","","")
    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.handleResize.bind(this));
        if(this.canTrackingEnd){
            apiService.viewerTracking({page:'projects',type:'club',action:'end'});
            this.canTrackingEnd=false;
        }
    }

    /**
     * 更新容器大小
     */
    handleResize(){
        this.setState({curWindowWidth:commonFun.getWidth()})
    }

    /**
     * 获取Project数据
     * @param result
     */
    getProjectData(result){
        if(!commonFun.isNull(result)){
            if(result.length===this.limit){
                this.hasPage = true;
            }else{
                this.hasPage = false;
            }
            if(this.page>1){//翻页数据，对数据进行合并
                //result = result.concat(this.state.projectData)
                let tmp = this.state.projectData;
                for(let i=0;i<result.length;i++){
                    tmp.push(result[i]);
                }
                result = tmp;
                tmp = null;
            }
            this.setState({projectData:result})
        }
    }

    /**
     * 加载数据
     */
    loadMore(){
        this.page += 1;
        apiService.getProjects({page:this.page,limit:this.limit},this.getProjectData.bind(this));
    }

    render() {
        return <div className={styles.pageContainer}>
            {
                commonFun.isNull(this.state.projectData)
                    ? null
                    : <div className={styles.pageContainerTopBackground}>
                    </div>
            }

            {
                commonFun.isNull(this.state.projectData)
                    ? null
                    : <div className={styles.projectContainer}>
                        <div className={styles.projectBigTitleContainer}>
                            <div className={styles.projectBigTitle}>CREATORS WANTED</div>
                        </div>
                        {/**第1个大图**/
                            commonFun.isNull(this.state.projectData[0])
                                ? null
                                : <a href={commonFun.isNull(this.state.projectData[0]['href']) ? '/club/projects/detail/'+this.state.projectData[0]['id'] : this.state.projectData[0]['href']} className={styles.firstProject}>
                                    <div className={styles.firstProjectImg}>
                                        <img src={this.state.curWindowWidth>768 ? this.state.projectData[0]['poster'] : this.state.projectData[0]['posterm']} alt='' />
                                        {
                                            this.state.projectData[0]['status']
                                                ? <div className={`${styles.projectStatusBox} ${styles.projectStatusClose}`}>Closed</div>
                                                : <div className={`${styles.projectStatusBox} ${styles.projectStatusActive}`}>On-going</div>
                                        }
                                    </div>
                                </a>
                        }
                        <div className={styles.moreProject}>
                            {
                                this.state.projectData.map((item,key)=>{
                                    if(key===0 && this.state.curWindowWidth>768){
                                        return null;
                                    }
                                    return <a href={commonFun.isNull(item['href']) ? '/club/projects/detail/'+item['id'] : item['href']} key={`project_${key}`} className={styles.projectBox}>
                                        <div className={styles.projectBoxImg}>
                                            <img src={item['posterm']} alt=''/>
                                            {
                                                item['status']
                                                    ? <div className={`${styles.projectStatusBox} ${styles.projectStatusClose}`}>Closed</div>
                                                    : <div className={`${styles.projectStatusBox} ${styles.projectStatusActive}`}>On-going</div>
                                            }
                                        </div>
                                        <div className={styles.projectTitleContainer}>
                                            <div className={styles.projectTitle}>{item['projectName']}</div>
                                            <div className={styles.projectDateTime}>{item['date']}</div>
                                        </div>
                                    </a>;
                                })
                            }
                        </div>

                    </div>
            }

            {
                this.hasPage
                    ? <div className={styles.loadMore} onClick={()=>{this.loadMore()}}>Load More</div>
                    : null
            }
        </div>
    }
}