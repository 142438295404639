import React, {Component} from 'react';
import {Routes} from 'react-router'
import {BrowserRouter,Route} from 'react-router-dom'
import styles from '../css/PageLayout.module.css'
import MainNav from "../../commponents/MainNav";
import MyFooter from "../../commponents/MyFooter";
import PageIndex from "./creatorsClub/PageIndex";
import PageEvents from "./creatorsClub/PageEvents";
import PageEventsDetail from "./creatorsClub/PageEventsDetail";
import PageProjects from "./creatorsClub/PageProjects";
import PageRule from "./creatorsClub/PageRule";
import PageFeatures from "./creatorsClub/PageFeatures";
import PageProjectFeatures from "./creatorsClub/PageProjectFeatures";
import PageCreators from "./creatorsClub/PageCreators";
import PageCreatorsBlogs from "./creatorsClub/PageCreatorsBlogs";
import PageProjectsDetail from "./creatorsClub/PageProjectsDetail";
import PageRulePolicy from "./creatorsClub/PageRulePolicy";

/**
 * Creators Club 首页
 */
export default class PageLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {}

    render() {
        return <BrowserRouter>
            <div className={styles.pageContainer}>
                <div className={styles.navBar}>
                    <MainNav />
                </div>

                <div className={styles.content}>
                    <Routes>
                        <Route path="/" element={<PageIndex />} />
                        <Route path="/:language" element={<PageIndex />} />
                        <Route path="/club/events" element={<PageEvents />} />
                        <Route path="/club/events/detail/:id" element={<PageEventsDetail />} />
                        <Route path="/club/projects" element={<PageProjects />} />
                        <Route path="/club/projects/detail/:id" element={<PageProjectsDetail />} />
                        <Route path="/club/projects/features/:id/:title" element={<PageProjectFeatures />} />
                        <Route path="/club/features" element={<PageFeatures />} />
                        <Route path="/club/creators" element={<PageCreators />} />
                        <Route path="/club/creators/blogs/:id" element={<PageCreatorsBlogs />} />
                        <Route path="/club/rule" element={<PageRule />} />
                        <Route path="/club/rule/policy" element={<PageRulePolicy />} />
                    </Routes>
                </div>
                <MyFooter />
            </div>
        </BrowserRouter>
    }
}